import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "dataList" ]
  static values =  { endpointList: String, locale: String }

  showOptions(event) {
    let inputValue = this.inputTarget.value

    this.dataListTarget.innerHTML = ''
    if(inputValue.length >= 3) {
      fetch(`/${this.endpointListValue}?description=${inputValue}&locale=${this.localeValue}`)
        .then(response => response.json() )
        .then(data => {
          data.map(result => {
            const option = document.createElement('option')
            option.value = result
            this.dataListTarget.appendChild(option)
          })
        })
        .catch(err => {})
    }
  }
}
