import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["grid", "checkbox"]

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (this.hasCheckboxTarget) {
      if (this.checkboxTarget.checked) {
        this.gridTarget.style.pointerEvents = "none"
        this.gridTarget.style.opacity = "0.5"
      } else {
        this.gridTarget.style.pointerEvents = ""
        this.gridTarget.style.opacity = ""
      }
    }
  }
}
