import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputTypeFile", "buttonUploadFile", "containerUploadedFile", "documentTypeSelector", "tagNameFile", "actionDeleteTag"]
  static values = {modelName: String}

  connect() {
    this.deleteDuplicateDocuments()
  }

  deleteDuplicateDocuments() {
    const select = this.documentTypeSelectorTarget
    const optionsForDelete = Array.from(document.querySelectorAll("[id='document_type_selector']"))
      .map(option => option.value)
      .filter(option => option != "select_document")

    const currentOptions = Array.from(select.options)
    const newOptions = currentOptions.filter( option => !optionsForDelete.includes(option.value) );

    let optionsInHtml = ""
    for (var option of newOptions) {
      optionsInHtml += `<option value='${option.value}'>` + option.text + "</option>"
    }
    select.innerHTML = optionsInHtml
  }
  changeInput(event) {
    const inputUploadFile = this.inputTypeFileTarget
    const buttonUploadFile = this.buttonUploadFileTarget

    const currentValue = event.currentTarget.value
    inputUploadFile.setAttribute('name', `${this.modelNameValue}[${currentValue}]`)
    buttonUploadFile.classList.remove('hidden')

    if(currentValue === "select_document") {
      buttonUploadFile.classList.add('hidden')
    }

    this.setInputAndContainerInEmpty()
    this.removeDocument()
  }

  showDocument() {
    const inputUploadFile = this.inputTypeFileTarget
    const tagNameFile = this.tagNameFileTarget
    const actionDeleteTag = this.actionDeleteTagTarget

    tagNameFile.textContent = inputUploadFile.files[0].name
    actionDeleteTag.classList.remove('hidden')

    this.buttonUploadFileTarget.classList.add('hidden')
    this.disabledSaveButton()
  }

  removeDocument() {
    this.setInputAndContainerInEmpty()

    const actionDeleteTag = this.actionDeleteTagTarget
    actionDeleteTag.classList.add('hidden')

    this.buttonUploadFileTarget.classList.remove('hidden')
    this.removeDisabledInButtonFile()
    this.disabledSaveButton()
  }

  removeDisabledInButtonFile() {
    const buttonUploadFile = this.buttonUploadFileTarget

    buttonUploadFile.removeAttribute('disabled')
    buttonUploadFile.classList.remove("pointer-events-none")
  }

  setInputAndContainerInEmpty() {
    const inputUploadFile = this.inputTypeFileTarget

    inputUploadFile.value = ""
  }
  disabledSaveButton() {
    const saveButton = document.getElementById('save-button-documents')
    if (saveButton) {
      if(this.inputTypeFileTarget.value == '') {
        saveButton.setAttribute('disabled', true)
      } else {
        saveButton.removeAttribute('disabled')
      }
    }
  }
}
