import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "endDate", "onGoingStudy", "input" ]

  toggleRequiredEndingDates(){
    const finishDates = this.inputTargets

    if(this.onGoingStudyTarget.checked) {
      this.endDateTarget.setAttribute('hidden', true)

      finishDates.forEach(element => {
        element.value = ''
        element.removeAttribute('required')
      })

    } else {
      this.endDateTarget.removeAttribute('hidden')

      finishDates.forEach(element => {
        element.setAttribute('required', 'true')
      })
    }
  }
}
