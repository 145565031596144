import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["list", "textarea", "listskills", "templateskills", "add_card_object", "input_company", "context-personal", "context-experience", "context-skills", "formSkills"]
  
  connect(){
    this.limit = parseInt(this.formSkillsTarget.dataset.limit)
  }

  handleSubmitForm(event) {
    const {context} = event.params
    const formSearch = event.target.closest('.form__box-x')

    let textSuggestion = formSearch.querySelector("input").value

    if(context === "experience"){
      this.getJobDescriptions(textSuggestion)
    }else {
      this.getSkillsDescriptions(textSuggestion)
    }
  }

  handleChangePosition(event){
      let selectPosition = event.target
      let selectPositionText = selectPosition.options[selectPosition.selectedIndex].innerHTML

      this.getJobDescriptions(selectPositionText)
      this.getSkillsDescriptions(selectPositionText)
      this.getStepContext(null, "experience")
  }

  getJobDescriptions(textSuggestion) {
    this.showLoader(this.listTarget);

    fetch(`/openai/suggested_job_descriptions?text=${textSuggestion}`)
      .then(response => response.json())
      .then(data => {
        let listSuggestions = data.response;
        this.listTarget.innerHTML = '';
  
        if(!listSuggestions) return
        listSuggestions.forEach(suggested => {
          let card = this.generateCard(suggested, "click->suggested-job-descriptions#addSuggestionToTrix");
          this.listTarget.appendChild(card);
        });
      });
  }
  
  getSkillsDescriptions(textSuggestion) {
    this.showLoader(this.listskillsTarget);

    fetch(`/openai/suggested_skills_descriptions?text=${textSuggestion}`)
      .then(response => response.json())
      .then(data => {
        let listSuggestions = data.response;
  
        this.listskillsTarget.innerHTML = '';
  
        if(!listSuggestions) return
        listSuggestions.forEach(suggested => {
          let card = this.generateCard(suggested, "click->suggested-job-descriptions#addSkillFormInput");
          this.listskillsTarget.appendChild(card);
        });
      });
  }
  
  generateCard(suggested, dataAction) {
    let card = document.createElement('label');
    card.className = "bg-white mb-2 block card shadow-none border border-solid border-blue-600 py-1";
    card.innerHTML = suggested;
    card.dataset.action = dataAction;
    return card;
  }
  
  generateShowMore() {
    let showMore = document.createElement('a');
    showMore.innerHTML = "Generar más opciones";
    showMore.className = "text-blue-600 text-center";
    return showMore;
  }

  showLoader(target) {
    let loader = document.createElement('div');
    loader.className = 'border-2 border-gray-200 border-t-white rounded-full w-8 h-8 animate-spin m-auto';
    loader.innerHTML = '';
    target.appendChild(loader);
  }

  addSuggestionToTrix(event){
    let textCard = event.target
    this.textareaTarget.value +=  textCard.innerHTML + "\n"

    textCard.classList.add("hidden");

    setTimeout(() => {
      this.textareaTarget.blur();
    }, 0);
  }

  addSkillFormInput(event) {
    let textCard = event.target.innerHTML

    // Count the existing skill cards
    const cardsSkills = Array.from(this.element.querySelectorAll(".skill-card")).filter(card => card.style.display !== 'none');

    if (cardsSkills.length >= this.limit) {
      // Limit reached
      return
    }

    const card = this.templateskillsTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
    this.add_card_objectTarget.insertAdjacentHTML('beforebegin', card)

    const updatedCardsSkills = this.element.querySelectorAll(".skill-card")
    const currentCardSkill = updatedCardsSkills[updatedCardsSkills.length - 1]

    let inputSkill = currentCardSkill.querySelector("#input-skill")
    inputSkill.value = textCard
    event.target.classList.add("hidden");
  }

  getStepContext(event, contextParam) {
    let context = "personal"
    if(event) {
      context  = event.params.context;  
    }else {
      context = contextParam
    }
    
    this.toggleElementVisibility(context);
  }

   toggleElementVisibility(currentStep) {
    const elements = this.element.querySelectorAll("[data-context]");

    if(!elements) return
    elements.forEach((element) => {
      const elementStep = element.getAttribute("data-context");
      
      if (elementStep === currentStep) {
        element.classList.remove("hidden");
        element.classList.add("fade-up");
        setTimeout(() => {
          element.classList.remove("fade-up");
        }, 500);
      } else {
        element.classList.add("hidden");
      }
    });
  }

  // For mobile
  showAssistant(event){
    const arrow = event.target
    arrow.classList.toggle("transform")
    arrow.classList.toggle("rotate-180")
    let assistant = this.element.querySelector("#assistant")
    assistant.classList.toggle("max-h-[90%]")
  }
}
