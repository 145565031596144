import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["academicCard", "languages", "languageCard", "academicError", "languageError"]

  validatePresence(e) {
    let academicValid = this.validateAcademicCards();
    let languageValid = this.hasLanguagesTarget ? this.validateLanguageCards() : true;

    if (!academicValid || !languageValid) {
      e.preventDefault();
    }
  }

  validateAcademicCards() {
    if (this.academicCardTargets.length === 0) {
      this.academicErrorTarget.classList.remove('hidden');
      return false;
    }
    
    return true;
  }

  validateLanguageCards() {
    if (this.languageCardTargets.length === 0) {
      this.languageErrorTarget.classList.remove('hidden');
      return false;
    }
    
    return true;
  }
}
