import { Controller } from "@hotwired/stimulus"

const triesNumber = 18; // the max attempts of confirm whatsapp status and update ui
const waitInterval = 5000; // wait time between each attempt in milliseconds
const minutesToConfirm = 1; // minutes for count down timer 
const secondsToConfirm = 30; // seconds for count down timer
// setInterval repeatedly executes the code snippet, with a fixed time delay between each call.
// clearInterval cancels a setInterval function when is called 

export default class extends Controller {
  static targets = ['unconfirmed', 'confirmed', 'timer']
 
  connect(){
    this.count = 0
    this.seconds = secondsToConfirm
    this.minutes = minutesToConfirm
    this.countDownTimer();
    this.checkStatus();
  }

  checkStatus(){
    this.checkStatusInterval = setInterval(() => {
      this.count++;
      this.handleConfirmedWhatsapp();
    
      if (this.count >= triesNumber) {
        clearInterval(this.checkStatusInterval);
      }
    }, waitInterval);
  }

  handleConfirmedWhatsapp(){
    fetch('/users/whatsapp_confirmations/')
    .then(response => response.json())
    .then(data => {
      if(data) {
        this.updateUI();
        clearInterval(this.checkStatusInterval);
        this.clearCountDownTimer();
      }
    })
    .catch(e => {});
  }

  updateUI(){
    this.confirmedTarget.classList.remove('hidden')
    this.unconfirmedTarget.classList.add('hidden')
  }

  clearCountDownTimer(){
    clearInterval(this.countDownInterval);
  }

  countDownTimer(){
    this.countDownInterval = setInterval(() => {

      if (this.seconds > 0) {
        this.seconds--;
      } else if (this.minutes > 0) {
        this.minutes--;
        this.seconds = 59;
      }

      this.timerTarget.innerHTML = `${this.minutes}:${this.seconds}`;

      if (this.minutes === 0 && this.seconds === 0) {
        // clearInterval cancels a setInterval function when is called 
        this.clearCountDownTimer();
      }
    }, 1000);
  }
}
