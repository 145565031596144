import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "fieldset" ]

  toggleForm(event) {
    if (!event.target.checked) {
      this.clearFields()
    }
  }

  clearFields() {
    this.fieldsetTargets.forEach(fieldset => {
      const inputs = fieldset.querySelectorAll('input, select, textarea')
      inputs.forEach(input => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          input.checked = false
        } else {
          input.value = ''
        }
      })
    })
  }
}
