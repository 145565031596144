import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  toggleRow(event){
    let currrentTableRow = event.target.closest("tr")
    let trHidden = currrentTableRow.nextElementSibling;
    trHidden.classList.toggle("hidden")
  }
}
