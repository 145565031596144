export default class SurveyFormUtils {
  // Show an element by removing 'hidden' class
  static showElement(el) {
    el.classList.remove('hidden');
  }

  // Hide an element by adding 'hidden' class
  static hideElement(el) {
    el.classList.add('hidden');
  }

  // Toggle the visibility of an element
  static toggleElement(el) {
    this.isHidden(el) ? this.showElement(el) : this.hideElement(el);
  }

  // Clone a node and copy its values
  static cloneWithValues(node) {
    const clone = node.cloneNode(true);
    const originalInputs = node.querySelectorAll("input, select");
    const cloneInputs = clone.querySelectorAll("input, select");

    originalInputs.forEach((originalInput, index ) => {
      cloneInputs[index].value = originalInput.value;
    });

    return clone;
  }

  // Validate form inputs
  static validateFormInputs(formElement) {
    let allValid = true;
    formElement.querySelectorAll('input, select').forEach(input => {
      if (!input.checkValidity()) {
        allValid = false;
        input.reportValidity();
      }
    });
    return allValid;
  }
}
