import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_object", "template", "remove_object", "template_card", "add_card_object"]

  initialize() {
    this.count = this.remove_objectTargets.length
    this.limit = this.add_objectTarget.dataset.limit
    this.keysForCard = ['institution_name', 'title', 'start_date', 'finish_date']
    this.keyNumber = 1 

    if (this.count == this.limit) this.add_objectTarget.style.display = "none"
    if (this.count == 1) this.remove_objectTarget.style.display = 'none'
  }

  add_association(event) {
    event.preventDefault()
    this.validateRenderForm()

    if (this.limit) {
      this.count++
      if (this.count <= this.limit) {
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, this.keyNumber)
        this.add_objectTarget.insertAdjacentHTML('beforebegin', content)
        this.addCard()
      }

      if (this.count == this.limit) {
        this.add_objectTarget.style.display = "none"
      }
    } else {
      let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, this.keyNumber)
      this.add_objectTarget.insertAdjacentHTML('beforebegin', content)
      this.addCard()
    }
  }

  remove_association(event) {
    event.preventDefault()
    if (this.limit) {
      this.count--
      if (this.count <= this.limit) {
        this.add_objectTarget.style.display = "block"
      }
    }
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    this.removeRequired(item)
    item.style.display = 'none'
  }

  count_fields() {
    return this.remove_objectTargets.length
  }

  addCard() {
    const { dataForCard, formToChange, currentForm } = this.getFormData()
    if (this.validateInsertCard(dataForCard, formToChange)) return
    const currentCard = this.buildCard(formToChange)
    this.fillDataCard(dataForCard, currentCard)
    this.hideForm(formToChange, currentForm)
    this.keyNumber ++
  }

  editForm(event) {
    const { form, forms, card } = this.getCurrrentObjects(event)
    const cardClass = ['card','border', 'border-solid', 'border-blue-600']
    const btnNew = this.element.querySelector('[data-nested-form-card-to-post-application-target="add_object"]')
    forms.forEach(element => element.style.display = 'none')
    if (form.style.display == 'block') {
      forms[forms.length - 1].style.display = 'block'
      btnNew.style.display = 'block'
      this.remove_objectTargets.forEach(elm => elm.style.display = 'block')
    } else {
      form.style.display = 'block'
      form.classList.add(...cardClass)
      btnNew.style.display = 'none'
      this.remove_objectTargets.forEach(elm => elm.style.display = 'none')
      this.fillEditDataCard(form, card, forms, btnNew)
    }
  }

  deleteForm(event) {
    const { card, form } = this.getCurrrentObjects(event)
    card.remove()
    form.remove()
    this.keyNumber --
  }

  getCurrrentObjects(event) {
    const card = event.target.closest('.w-full')
    const cardKey = card.getAttribute('child_index')
    const form = this.element.querySelector(`[data-key-to-card="${cardKey}"`)
    const forms = this.getCurrentForms()
    return {
      card,
      cardKey,
      form,
      forms
    }
  }

  getFormData() {
    const forms = this.getCurrentForms()
    const formToChange = forms[forms.length - 2]
    const dataForCard = this.buildDataForCard(formToChange)
    const currentForm = forms[forms.length - 1]
    return {
      dataForCard,
      formToChange,
      currentForm
    }
  }

  validateInsertCard(dataForCard, formToChange) {
    const title = dataForCard.find((obj) => obj.hasOwnProperty('title'))['title']
    const institutionName = dataForCard.find((obj) => obj.hasOwnProperty('institution_name'))['institution_name']
    const startDate = dataForCard.find((obj) => obj.hasOwnProperty('start_date'))['start_date']
    const existsForm = formToChange.getAttribute('data-key-to-card')
    if (title == '' || institutionName == '' || startDate == '' || existsForm !== null) {
      formToChange.nextElementSibling.remove()
      return true
    } else {
      return false
    }
  }

  getKeys(object) {
    const key = Object.keys(object)[0].split('[')
    return key[key.length - 1].replace(']', '')
  }

  buildCard(formToChange) {
    const card = this.template_cardTarget.innerHTML.replace(/TEMPLATE_RECORD/g, this.keyNumber)
    this.add_card_objectTarget.insertAdjacentHTML('beforeend', card)
    const currentCard = this.element.querySelector(`[child_index="${this.keyNumber}"`)
    this.relationFormToCard(this.keyNumber, formToChange)

    return currentCard
  }

  relationFormToCard(keyNumber, formToChange) {
    formToChange.setAttribute('data-key-to-card', keyNumber)
    formToChange.style.display = 'none'
  }

  fillDataCard(dataForCard, currentCard) {
    dataForCard.map(item => {
      const key = Object.keys(item)[0]
      const value = item[key]
      const element = currentCard.querySelector(`[data-name="${key}"]`)
      if (element) element.innerHTML = value
    })
    const fieldDate = currentCard.querySelector(`[data-name="field_date"]`)
    const startDate = dataForCard.find((obj) => obj.hasOwnProperty('start_date'))['start_date']
    const finishDate = dataForCard.find((obj) => obj.hasOwnProperty('finish_date'))['finish_date']
    fieldDate.innerHTML = finishDate ? `${startDate} - ${finishDate}` : `${startDate} - en curso`
  }

  buildDataForCard(form) {
    return Array.from(form.querySelectorAll('input'))
      .map(input => ({ [input.name]: input.value }))
      .filter(element => this.keysForCard.includes(this.getKeys(element)))
      .map(element => ({ [this.getKeys(element)]: element[Object.keys(element)[0]] }))
  }

  fillEditDataCard(form, card, forms, btnNew) {
    if (!form.querySelector('.edit-btn')) {
      const { btnBox, editBtn, cancelBtn } = this.createElements()
      editBtn.addEventListener('click', () => {
        const dataForCard = this.buildDataForCard(form)
        this.fillDataCard(dataForCard, card)
        this.closeForms(forms, btnNew)
      })
      cancelBtn.addEventListener('click', () => this.closeForms(forms, btnNew))
      form.appendChild(btnBox)
      btnBox.appendChild(editBtn)
      btnBox.appendChild(cancelBtn)
    }
  }

  getCurrentForms() {
    return this.element.querySelectorAll(".nested-fields")
  }

  validateRenderForm() {
    const currentForms = this.getCurrentForms()
    const lastForm = currentForms[currentForms.length - 1]
    if (lastForm.style.display == 'none') {
      lastForm.style.display = 'block'
      this.count--
      lastForm.querySelector("input[name*='_destroy']").value = 1
      this.removeRequired(lastForm)
    }
  }

  hideForm(formToChange, currentForm) {
    formToChange.querySelector("input[name*='_destroy']").value = 0
    currentForm.style.display = 'none'
    currentForm.querySelector("input[name*='_destroy']").value = 1
    this.removeRequired(currentForm)
  }

  removeRequired(form) {
    form.querySelectorAll('input, select, textarea').forEach(elem => elem.removeAttribute("required"))
  }

  createElements() {
    const btnBox = document.createElement('div')
    btnBox.setAttribute('class', 'form__box-x lg:mb-7')
    const editBtn = document.createElement('div')
    editBtn.setAttribute('class', 'btn --primary edit-btn md:w-1/2')
    editBtn.innerHTML = 'Guardar'
    const cancelBtn = document.createElement('div')
    cancelBtn.setAttribute('class', 'btn --secondary cancel-btn md:w-1/2')
    cancelBtn.innerHTML = 'Cancelar'
    return {
      btnBox,
      editBtn,
      cancelBtn
    }
  }

  closeForms(forms, btnNew) {
    forms.forEach(element => element.style.display = 'none')
    this.removeRequired(forms[forms.length - 1])
    btnNew.style.display = 'block'
  }
}
