import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['action']

  connect() {
    this.clickActionTarget();
  }

  clickActionTarget(){
    // Don't submit when going back in the postulation form
    const referrer = document.referrer;
    const regex = /\/offers\/candidate_/;

    if (!regex.test(referrer)) {
      this.actionTarget.click();
    }
  }
}

