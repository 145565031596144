import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "textContent", "counter", "message", "error" ]

  static defaultMinCharacters = 40
  static defaultMaxCharacters = 800

  get textContent() {
    return this.textContentTarget.value;
  }

  countCharacters(event) {
    let { charactersLength, minCharacters } = this.getCharacterData()

    if ( charactersLength > 0 && this.counterTarget) {
      this.counterTarget.hidden = false
      this.counterTarget.textContent = charactersLength;
      if (charactersLength < minCharacters || charactersLength > this.defaultMaxCharacters) {
        this.element.style.color = "red"
      } else  {
        this.element.style.color = "green"
      }
    } else  {
      this.counterTarget.hidden = true
    }
  }

  validateCharacters() {
    let { charactersLength, minCharacters } = this.getCharacterData()

    if ( charactersLength > 0 && this.counterTarget) {
      this.counterTarget.hidden = false
      this.counterTarget.textContent = charactersLength;
      if (charactersLength < minCharacters || charactersLength > this.defaultMaxCharacters) {
        this.showError(false)
      } else  {
        this.showError(true)
      }
    } else  {
      this.showError(true)
    }
  }
  showError(show){
      this.errorTarget.hidden = show
      this.messageTarget.hidden = !show
      this.element.firstElementChild.style.borderColor = !show && "red"
      show && this.element.firstElementChild.style.removeProperty('border-color')
      !show && (this.errorTarget.textContent = this.element.dataset.errormessage)
  }

  getCharacterData() {
    let charactersLength = this.textContent.length;
    let minCharacters = this.element.dataset.mincharacters || this.defaultMinCharacters

    return {
      charactersLength: charactersLength,
      minCharacters: minCharacters
    }
  }
}
