import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "loadingIndicator", "items", "checkbox"];

  connect() {
    this.addEvents();
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  showLoadingIndicator() {
    this.loadingIndicatorTargets.forEach(indicator => {
      indicator.style.display = 'grid';
    });
    this.hideItems();
  }

  hideLoadingIndicator() {
    this.loadingIndicatorTargets.forEach(indicator => {
      indicator.style.display = 'none';
    });
    this.showItems();
  }

  addEvents() {
    this.formTarget.addEventListener('submit', () => {
      this.showLoadingIndicator();
    });
    

    this.formTarget.addEventListener('turbo:submit-end', (event) => {
      this.hideLoadingIndicator();
    });
  }

  hideItems() {
    this.itemsTarget.style.display = 'none';
  }

  showItems() {
    this.itemsTarget.style.display = 'block';
  }
}
