import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  
  // Scroll to a target referenced by id
  // Eg an element <a> with: data-action: 'click->scroll-to#scrollToTarget', 'data-target-to-scroll': 'offers-turbo-wrapper'
  // After clicking will scroll to the element with id offers-turbo-wrapper 

  scrollToTarget(event) {
    const targetId = event.currentTarget.dataset.targetToScroll;

    if (targetId) {
      const element = document.getElementById(targetId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
}
