import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["dropdown", "arrow"];

  toggle() {
    if (this.dropdownTarget.classList.contains('opacity-0')) {
      this.dropdownTarget.classList.remove('opacity-0', '-z-10', 'pointer-events-none');
    } else {
      this.dropdownTarget.classList.add('opacity-0', '-z-10', 'pointer-events-none');
      setTimeout(() => {
        // Ensuring that the z-index is changed after the transition is completed
        this.dropdownTarget.classList.remove('-z-10');
      }, 300);
    }
    this.arrowTarget.classList.toggle('rotate-180');
  }
}
