import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkItem", "clearSpan", "checkItemsCounter", "selectAll"]

  connect() {
    this.toggleClearSpanVisibility()
    this.updateCheckItemsCounter()
    this.updateSelectAll()
  }

  toggleClearSpanVisibility() {
    const isAnyChecked = this.checkItemTargets.some(checkbox => checkbox.checked)
    this.clearSpanTarget.style.display = isAnyChecked ? 'flex' : 'none'
    this.updateCheckItemsCounter()
  }

  updateCheckItemsCounter() {
    const checkedCount = this.checkItemTargets.filter(checkbox => checkbox.checked).length
    this.checkItemsCounterTarget.textContent = checkedCount
  }

  updateSelectAll() {
    const checkedCount = this.checkItemTargets.filter(checkbox => checkbox.checked).length
    const checkItemCount = this.checkItemTargets.length

    if(checkedCount == checkItemCount) {
      this.selectAllTarget.checked = true
    }
  }

  deselect() {
    this.checkItemTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.selectAllTarget.checked = false
    this.toggleClearSpanVisibility()
    this.requestRemoveValue()
  }

  requestRemoveValue() {
    fetch('/session_state', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
  }
}
