import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "recruiterSelect", "recruiterCheckboxes" ]

  connect() {
    this.recruiterIds = JSON.parse(this.recruiterCheckboxesTarget.dataset.recruiterids)
    const hasBusinessAssociated = this.recruiterCheckboxesTarget.hasAttribute('data-hasbusinessassociated')

    if (hasBusinessAssociated) {
      this.getRecruitersAsCheckboxes()
    }
  }

  getRecruitersAsCheckboxes() {
    const selectedBusinessId = this.recruiterSelectTarget.value
  
    fetch(`/api/recruiters?business_id=${selectedBusinessId}`)
      .then(response => response.json())
      .then(data => {
        this.recruiterCheckboxesTarget.innerHTML = ''
        data.forEach(recruiter => this.createCheckboxForRecruiter(recruiter))
      })
  }

  createCheckboxForRecruiter([label, id]) {
    // Create input checkbox
    const checkbox = document.createElement('input')
    checkbox.type = 'checkbox'
    checkbox.className = 'check_boxes optional'
    checkbox.name = 'offer[assigned_recruiter_ids][]'
    checkbox.value = id
    checkbox.id = `offer_assigned_recruiter_ids_${id}`

    // If the recruiter is associated with the offer, check the checkbox
    if (this.recruiterIds.includes(id)) {
      checkbox.checked = true
    }

    // Create the label for the checkbox
    const checkboxLabel = document.createElement('label')
    checkboxLabel.className = 'collection_check_boxes'
    checkboxLabel.htmlFor = `offer_assigned_recruiter_ids_${id}`
    checkboxLabel.textContent = label

    // Create the span that wraps the checkbox and its label
    const span = document.createElement('span')
    span.className = 'checkbox'
    span.appendChild(checkbox)
    span.appendChild(checkboxLabel)

    // Add the span to the DOM
    this.recruiterCheckboxesTarget.appendChild(span)
  }
}