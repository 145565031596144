import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    [
      "endDate",
      "timePeriod",
      "stillInProgress",
      "validation",
      "checkDatesExperience",
      "checkStillInProgress",
      "endDateField"
    ]

  initialize() {
    if(this.hasTimePeriodTarget){
      this.timePeriodTarget.style.display = "none";
    }
    this.toggleRequiredEndingDates()
  }

  //toogleRequiredEndingDates is used in user/work_experiences/_form.haml
  //toggleTargets is used in offer/candidates/_work_experience_fields.haml

  toggleRequiredEndingDates(){
    const endMonth = this.endDateFieldTarget.querySelector('#work_experience_finished_at_2i')
    const endYear = this.endDateFieldTarget.querySelector('#work_experience_finished_at_1i')

    if(this.stillInProgressTarget.checked) {
      endYear.value = ''
      endYear.removeAttribute('required')
      endMonth.value = ''
      endMonth.removeAttribute('required')
    } else {
      this.endDateFieldTarget.removeAttribute('hidden')
      endMonth.setAttribute('required', 'true')
      endYear.setAttribute('required', 'true')
    }
  }

  toggleTargets() {
    // Toggle display and visibility
    if(this.checkDatesExperienceTarget.checked) {
      this.checkStillInProgressTarget.checked = false
    }

    this.endDateTarget.style.display =  (this.endDateTarget.style.display == 'none' && this.checkDatesExperienceTarget.checked) ? "inline-grid" : 'none'

    this.stillInProgressTarget.style.display =  (this.stillInProgressTarget.style.display == 'none' && this.checkDatesExperienceTarget.checked) ? "flex" : 'none'
    this.timePeriodTarget.style.display =  (this.timePeriodTarget.style.display == 'none' && !this.checkDatesExperienceTarget.checked) ? "block" : 'none'
    this.toggleValidationTargets()
  }

  toggleEndDate(event) {
    this.endDateTarget.style.display =  (this.endDateTarget.style.display == 'none' && this.checkDatesExperienceTarget.checked) ? "inline-grid" : 'none'

    const inputsDateEnd = this.endDateTarget.querySelectorAll('select');
    if(event.target.checked){
      inputsDateEnd.forEach(element => {
        element.value = ""
      });
    }
  }
}
