import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

export default class extends Controller {
  static values = { items: Array };
  connect() {
    console.log(this.itemsValue);

    this.calendar = new Calendar(this.element, {
      plugins: [dayGridPlugin],
      events: this.itemsValue,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay'
      },
      editable: true
    });
    this.calendar.render();
  }
}
