import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [ "anchor" ]
  }

  encode({ target: { name, value } }) {
    for (const anchor of this.anchorTargets) {
      anchor.search = new URLSearchParams({ [name]: value })
    }
  }
}
