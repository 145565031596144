import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "backdrop", "box"]

  connect() {
    document.addEventListener("modal-event:open-modal", this.openFromEvent.bind(this))
    document.addEventListener("modal-event:close-modal", this.closeFromEvent.bind(this))
    document.addEventListener("keydown", this.handleKeyDown.bind(this))
  }

  disconnect() {
    document.removeEventListener("modal-event:open-modal", this.openFromEvent.bind(this))
    document.removeEventListener("modal-event:close-modal", this.closeFromEvent.bind(this))
    document.removeEventListener("keydown", this.handleKeyDown.bind(this))
  }

  open(event) {
    event.preventDefault()
    this.modalTarget.classList.add("modal-visible")
    this.backdropTarget.classList.add("modal-visible")
  }

  openFromEvent(event) {
    if (event.detail.modalId === this.modalTarget.id) {
      this.open(event)
    }
  }

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.remove("modal-visible")
    this.backdropTarget.classList.remove("modal-visible")
  }

  closeFromEvent(event) {
    if (event.detail.modalId === this.modalTarget.id) {
      this.close(event)
    }
  }

  closeOnBackdropClick(event) {
    if (event.target === this.backdropTarget) {
      this.close(event)
    }
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close(event)
    }
  }
}
