import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['option1', 'option2', 'option3', 'input1', 'input2', 'input3', 'loadSection', 'suggestedSection', 'companyName', 'description', 'jobCategory', 'workFunction', 'startedAt', 'finishedAt', 'submitForm', 'stillInProgress']

  connect() {
    this.checkForm()

    const workExperienceId = this.element.dataset.workExperienceId
    if (!workExperienceId) {
      return
    }

    const formValues = {
      work_experience_id: workExperienceId
    }

    this.fillFields(formValues)
  }

  submitForm(event) {
    event.preventDefault()

    this.loadSectionTarget.classList.remove('hidden')

    const [entryMonth, entryYear] = this.startedAtTargets
    const [departureMonth, departureYear] = this.finishedAtTargets

    const formValues = {
      company_name: this.companyNameTarget.value,
      description: this.descriptionTarget.value,
      job_category_id: this.jobCategoryTarget.value,
      work_function: this.workFunctionTarget.value,
      started_at: `01/${entryMonth.value}/${entryYear.value}`,
      finished_at: `01/${departureMonth.value}/${departureYear.value}`,
      still_in_progress: this.stillInProgressTarget.checked
    }

    this.fillFields(formValues)
  }

  fillFields(formValues) {
    this.totalOptions = []

    const targetNames = ['option1', 'input1', 'option2', 'input2', 'option3', 'input3']

    for (let i = 0; i < targetNames.length; i += 2) {
      const optionTarget = targetNames[i]
      const labelTarget = targetNames[i + 1]

      this.loadOptionData(optionTarget, labelTarget, formValues)
    }
  }

  loadOptionData(optionName, labelName, formValues) {
    fetch(`/openai/suggested_descriptions_by_candidate/get_suggestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formValues),
    }).then(response => response.json())
      .then(data => {
        this.totalOptions.push(labelName)
        this[`${optionName}Target`].value = data['response'];
        this[`${labelName}Target`].value = data['response'];

        if(this.totalOptions.length == 3) {
          this.loadSectionTarget.classList.add('hidden')
          this.suggestedSectionTarget.classList.remove('hidden')
          this.totalOptions.map(option => {
            this[`${option}Target`].style.height = `${this[`${option}Target`].scrollHeight}px`
          })
        }
      })
  }

  // Check if the specified fields AI are filled
  checkForm() {
    const isCompanyNameFilled = this.companyNameTarget.value.trim() !== '';
    const isDescriptionFilled = this.descriptionTarget.value.trim() !== '';
    const isJobCategoryFilled = this.jobCategoryTarget.value.trim() !== '';
    const isWorkFunctionFilled = this.workFunctionTarget.value.trim() !== '';

    // Check if the start date is filled
    const [entryMonth, entryYear] = this.startedAtTargets;
    const isStartDateFilled = entryMonth.value.trim() !== '' && entryYear.value.trim() !== '';

    const isStillInProgress = this.stillInProgressTarget.checked;

    // Check if the finish date is filled (only if the work is not still in progress)
    let isFinishDateFilled = true;
    if (!isStillInProgress) {
      const [departureMonth, departureYear] = this.finishedAtTargets;
      isFinishDateFilled = departureMonth.value.trim() !== '' && departureYear.value.trim() !== '';
    }

    // Enable "Potencias con inteligencias Artificial" button if all specific fields are filled
    this.submitFormTarget.disabled = !(isCompanyNameFilled && isDescriptionFilled && isJobCategoryFilled && isWorkFunctionFilled && isStartDateFilled && isFinishDateFilled);
  }
}
