import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'checkboxAll'];

  connect() {
    this.updateCheckboxes();
  }

  toggle(event) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    });
  }

  updateCheckboxes() {
    this.checkboxAllTarget.checked = !this.checkboxTargets.map(x => x.checked).includes(false)
  }
}
