import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'cleanBtn', 'offer']

  initialize() {
    this.form = this.formTarget
    this.cleanBtn = this.cleanBtnTarget
    this.inputs = this.form.querySelectorAll('input[type="text"], input[type="checkbox"], select')
    this.filterForm = this.form.querySelector('.toggle-filter-box')
    this.orderForm = this.form.querySelector('.toggle-order-box')
  }

  connect(){
    this.addEvent(this.filterForm)
    this.setCounter(this.filterForm, 'toggle-filter-box')
    this.setCounter(this.orderForm, 'toggle-order-box')
    this.scrollToLastViewedOffer()
  }

  cleanForm(event) {
    event.preventDefault()
    this.form.reset()
    this.cleanBtn.disabled = true
  }

  closeForm(event) {
    const tag = event.target
    const section = tag.dataset.section

    if (section) {
      const elem = this.element.querySelector(`.${section}`)
      elem.classList.toggle('hidden')

      if (elem.classList.contains('hidden')) {
        this.setCounter(elem, section)
      }
    }
  }

  setCounter(elem, section) {
    const checkedInputs = elem.querySelectorAll('input:checked')
    const counter = checkedInputs.length
    const sectionDataTag = this.element.querySelector(`[data-tag="${section}"]`)
    const sectionAlt = this.element.querySelector(`[alt="${section}"]`)
    const isSectionHidden = sectionDataTag.classList.contains('hidden')

    if (counter > 0) {
      if (isSectionHidden) {
        this.toggleSection(sectionDataTag)
        this.updateCounter(sectionDataTag, counter)
        this.toggleSection(sectionAlt)
      } else {
        this.updateCounter(sectionDataTag, counter)
        this.hideSection(sectionAlt)
      }
    } else {
      this.showSection(sectionAlt)
      this.hideSection(sectionDataTag)
    }
  }

  showSection(section) {
    section.classList.remove('hidden')
  }

  hideSection(section) {
    section.classList.add('hidden')
  }

  toggleSection(section) {
    section.classList.toggle('hidden')
  }

  updateCounter(section, count) {
    section.textContent = count
  }

  addEvent(section) {
    const checkboxes = section.querySelectorAll('input[type="checkbox"]')
    const checkedInputs = section.querySelectorAll('input:checked')
    const counter = checkedInputs.length
    if(counter > 0) this.cleanBtn.disabled = false
    checkboxes.forEach(elm => elm.addEventListener('change', (e) => {
      if(e.target.checked) this.cleanBtn.disabled = false
    }))
  }

  submit(){
    this.form.submit()
  }

  scrollToLastViewedOffer() {
    const maxLimitParam = new URLSearchParams(window.location.search).get('max_limit');

    if (maxLimitParam) {
      const lastViewedOffer = this.offerTargets[maxLimitParam - 20];
      lastViewedOffer.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
