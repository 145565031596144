import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectContractType(event) {
    if (this.isServiceProvision(event)) {
      this.dispatch("service-provision")
    }
    else {
      this.dispatch("not-service-provision")
    }
  }

  isServiceProvision(event){
    var selectElement = document.getElementById(event.target.id);
    var selectedIndex = selectElement.selectedIndex;
    var selectedDescription = selectElement.options[selectedIndex].text;

    return selectedDescription === "Prestación de servicios"
  }
}