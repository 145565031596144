import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["template"]

  add_association(event) {
    event.preventDefault()

    let lastCard = Array.from(document.querySelectorAll('.nested-fields')).pop();

    let fieldCompany = lastCard.querySelector('[data-name="company"]')

    let containerFields = fieldCompany.parentNode.parentNode.parentNode.parentNode
    let fieldTitleInput = lastCard.querySelector('[data-name="position_held"]')

    if(fieldCompany.value != '' && typeof fieldTitleInput != 'undefined') {
      this.createCard(lastCard)

      const containerCards = document.querySelector('#container-cards')

      let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())

      containerCards.appendChild(this.createElementFromHTML(content))
    } else {
      containerFields.parentNode.style.display = 'block'
      containerFields.style.display = 'block'
      fieldCompany.focus()
    }

    if(event.target.id === "btn-add_association-main") {
      event.target.classList.add("hidden")
    }
  }

  confirm(event) {
    let nestedFields = event.target.parentNode.parentNode.parentNode.parentNode
    let form = event.target.parentNode.parentNode.parentNode.parentNode.parentNode

    nestedFields.classList.remove('two-columns')

    this.createCard(nestedFields)

    form.querySelector('.btn-add').classList.remove('hidden')
  }

  createCard(card) {
    let fieldTitleInput = card.querySelector('[data-name="position_held"]')
    let positionHeld = fieldTitleInput.options[fieldTitleInput.selectedIndex].text;

    if(typeof fieldTitleInput.value != 'undefined') {
      card.querySelector('.card').classList.remove('hidden')

      let fieldCompany = card.querySelector('[data-name="company"]')

      let titleElement = card.querySelector('#title-card')
      let companyElement = card.querySelector('#company-card')

      card.querySelector('.fields').style.display = "none"

      titleElement.innerHTML = positionHeld
      companyElement.innerHTML = fieldCompany.value

      this.validateDateCardcard(card)
    }
  }

  validateDateCardcard(card) {
    let fieldStartedDate = card.querySelector('[data-name="started_date"]')
    let fieldFinishedDate = card.querySelector('[data-name="finished_date"]')
    let startedDateElement = card.querySelector('#started-date-card')
    let finishedDateElement = card.querySelector('#finished-date-card')
    let workInProgressElement = card.querySelector('#work-in-progress')

    startedDateElement.innerHTML = fieldStartedDate.value

    if(fieldFinishedDate.value.length > 1) {
      finishedDateElement.classList.remove('hidden')
      finishedDateElement.innerHTML = fieldFinishedDate.value
    } else {
      workInProgressElement.classList.remove('hidden')
    }
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  edit(event) {
    let nestedFields = event.target.parentNode.parentNode.parentNode
    let form = event.target.parentNode.parentNode.parentNode.parentNode

    nestedFields.classList.add('two-columns')

    document.querySelectorAll('.fields').forEach(element => element.style.display = "none")

    nestedFields.querySelector('.card').classList.add('hidden')

    let fields = nestedFields.querySelector('.fields')
    nestedFields.querySelector('.btn-confirm').classList.remove('hidden')
    fields.style.display = "block"

    fields.classList.add('card')
    form.querySelector('.btn-add').classList.add('hidden')
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1

    const buttoAddAssociationMain = document.getElementById("btn-add_association-main")
    buttoAddAssociationMain.classList.remove("hidden")

    item.querySelector('[data-name="work_position"]').removeAttribute('required')
    item.querySelector('[data-name="job_category"]').removeAttribute('required')
    item.querySelector('[data-name="position_held"]').removeAttribute('required')
    item.querySelector('[data-name="started_date"]').removeAttribute('required')
    item.querySelector('[data-name="finished_date"]').removeAttribute('required')
    item.querySelector('[data-name="company"]').removeAttribute('required')

    item.style.display = 'none'
  }
}
