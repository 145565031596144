import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdowncontent", "arrow"];

  toggle() {
    const dropdownContent = this.dropdowncontentTarget;

    dropdownContent.style.display = dropdownContent.style.display === 'block' ? 'none' : 'block';
    this.arrowTarget.classList.toggle('rotate-180');

  }
}
