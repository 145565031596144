import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['actions', 'fieldset', 'submit', 'hrTop']

  hidde() {
    this.actionsTarget.classList.toggle('hidden')
    this.submitTarget.classList.toggle('hidden')
    this.fieldsetTarget.classList.toggle('hidden')
  }
}
