import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [ 'container', 'slideIdentifier' ]
  }

  connect() {
    this.position = 0
    this.basePosition = 100
    this.totalSlides = parseInt(this.containerTarget.dataset.total)
    this.baseTranslate = parseInt(this.containerTarget.dataset.baseTranslate) + this.basePosition 
    this.initCarousel()
  }

  disconnect() {
    if(this.autoPlay) clearInterval(this.autoPlay)
  }

  initCarousel() {
    this.autoPlay()
    this.setActiveSlideIdentifier(this.totalSlides - 1, 'next')
  }

  autoPlay() {
    setInterval(() => this.nextSlide(), 12000)
  }

  nextSlide() {
    const activePosition = this.position/this.baseTranslate 
    const newPosition = this.position + this.baseTranslate
    const isLastPosition = this.totalSlides === (activePosition + 1) 
    isLastPosition ? this.translate(0) : this.translate(newPosition) 
    this.setActiveSlideIdentifier(activePosition, 'next')
  }

  previousSlide() {
    if(this.position === 0) return
    const newPosition = this.position - this.baseTranslate
    this.translate(newPosition)
    const activePosition = this.position/this.baseTranslate 
    this.setActiveSlideIdentifier(activePosition, 'previous')
  }

  translate(position) {
    this.containerTarget.style.transform = `translateX(-${position}%)` 
    this.position = position
  }

  setActiveSlideIdentifier(activePosition, control) {
    const { slideIdentifierTargets, totalSlides } = this
    slideIdentifierTargets.forEach(elm => elm.classList.remove('active-slide-identifier'))
    const isFirstSlide = control === 'next' ? activePosition + 1 === totalSlides : activePosition === 0
    const currentPosition = control === 'next' ? activePosition + 1 : activePosition
    const slideIndex = isFirstSlide ? 0 : currentPosition
    this.slideIdentifierTargets[slideIndex]?.classList.add('active-slide-identifier')
  }

}
