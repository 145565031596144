import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['country_id', 'state_id', 'city', 'test']

  changeState() {
    let state_id = this.state_idTarget.value

    fetch(`/cities?state_id=${state_id}`)
      .then(response => response.text())
      .then(html => {
        this.cityTarget.innerHTML = this.setSelectedDefault(html)
      })
  }

  changeCountry() {
    let country_id = this.country_idTarget.value

    fetch(`/states?country_id=${country_id}`)
      .then(response => response.text())
      .then(html => {
        this.state_idTarget.innerHTML = html
      })
  }

  setSelectedDefault(nodeList) {
    let doc = new DOMParser().parseFromString(nodeList, 'text/html');
    let selectElement = doc.querySelector('select');        
    let options = selectElement.querySelectorAll('option');

    options[0].setAttribute('selected', 'selected')
    return doc.documentElement.outerHTML;
  }
}
