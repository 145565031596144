import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "minElement", "maxElement"]

    get minValueReturn() {
        return parseInt(this.minElementTarget.value);
    }

    get maxValueReturn() {
        return this.maxElementTarget;
    }

    connect() {
        this.setAgeMinElement();
    }

    setAgeMinElement(){
        this.setMinAge();
        this.setMaxAttributeValue();
    }

    setMinAge(){
        this.minAgeInput = (this.minValueReturn > 0) ? this.minValueReturn : null;
    }

    setMaxAttributeValue(){
        if(this.minAgeInput !== null){
            this.maxValueReturn.setAttribute("min", this.minAgeInput);
        }
    }
}
