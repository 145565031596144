import { Controller } from "@hotwired/stimulus"
import SurveyFormUtils from './SurveyFormUtils'

export default class extends Controller {
  static targets = [ 'sqFieldset', 'sqCard',  'sqIndexTitle', 'responseOptionsBox', 'responseOptionsList', 'toDiscard', 'sqCardsList' ,
    'cardStatement', 'cardQuestionType', 'cardCorrectOption', 'cardCorrectOptionText', 'statement', 'questionType', 'createResponseOptionBtn',
    'newSurveyQuestionBtn', 'saveBackBtns', 'sqTemplate', 'responseOptionTemplate', 'placeholder', 'cardQuestionTypeText', 'oneChoiceResponseText',
    'newSurveyQuestionField', 'sqIndexTitleNew', 'newSurveyQuestionTextAux', 'toDiscardInput', 'toDiscardText', 'warningMessage',
    'deleteCard', 'hr', 'toolTip', 'disabledOptionText'
  ]

  //activeIndex == this is the index for the card o form than I will worker
  connect(){
    this.count = this.sqCardTargets.length
    this.activeIndex = null
    this.hideCorrectOptionFieldForTextArea()
    this.showOrHideHrDivider()
  }

  get responseOptionRows(){
    return this.responseOptionsListTargets[this.activeIndex]
  }

  get surveyFields(){
    return this.sqFieldsetTargets[this.activeIndex]
  }

  get questionType(){
    return this.questionTypeTargets[this.activeIndex]
  }
  
  createSurveyQuestion() {
    this.cloneAndAppendSqCard();
    this.cloneAndAppendSqFieldset();

    this.clearNewSurveyQuestionField();
    this.updateUIAfterCreation();
  }

  cloneAndAppendSqCard() {
    const lastSqCard = this.sqCardTargets.at(-1);
    const clonedSqCard = lastSqCard.cloneNode(true);
    this.sqCardsListTarget.appendChild(clonedSqCard);
  }

  cloneAndAppendSqFieldset() {
    const clonedSqFieldset = SurveyFormUtils.cloneWithValues(this.surveyFields);
    this.sqCardsListTarget.appendChild(clonedSqFieldset);
  }

  clearNewSurveyQuestionField() {
    this.newSurveyQuestionFieldTarget.innerHTML = "";
  }

  updateUIAfterCreation() {
    this.updateCardTexts(this.activeIndex);
    this.updateIndexTitleNumber();
    this.hideActiveSurveyFields();
    this.incrementCountAndResetActiveIndex();
    this.showOrHideActionButtons();
  }

  hideActiveSurveyFields() {
    this.surveyFields.classList.add('hidden');
    this.surveyFields.removeAttribute('data-new-record');
  }

  incrementCountAndResetActiveIndex() {
    this.activeIndex = null;
    this.count++;
  }

  validateFieldset(){
    this.surveyFields.classList.add('form-submitted');

    this.verifyCorrectOptionText()

    const existingCard = this.surveyFields.dataset.existingCard

    if(this.hasMinimumOptions() && SurveyFormUtils.validateFormInputs(this.surveyFields) && this.hasCorrectOptionChecked()){
      this.questionType.removeAttribute('disabled')
      existingCard ? this.updateSurveyQuestion() : this.createSurveyQuestion()
      this.toggleDeleteButtons();
    }
  }

  changeResponseOptions(e){
    SurveyFormUtils.showElement(this.toDiscardTargets[this.activeIndex])
    SurveyFormUtils.hideElement(this.createResponseOptionBtnTargets[this.activeIndex])
    this.handleSelectedOption(e.target)
  }

  handleSelectedOption(option) {
    // Reset everything to default
    SurveyFormUtils.hideElement(this.responseOptionsBoxTargets[this.activeIndex]);
    this.responseOptionRows.innerHTML = "";
    this.setToFalseToDiscard(this.activeIndex);
    SurveyFormUtils.hideElement(this.toDiscardTargets[this.activeIndex]);
    SurveyFormUtils.hideElement(this.createResponseOptionBtnTargets[this.activeIndex]);
  
    // Delegate to specific handlers based on the selected option
    const handlers = {
      'multiple_choice': () => this.handleMultipleChoice(),
      'one_choice_as_radio_button': () => this.handleOneChoice(),
      'text_area': () => this.handleTextArea()
    };
  
    const handler = handlers[option.value];
    if (handler) {
      handler();
    }
  }
  
  handleMultipleChoice() {
    this.responseOptionRows.innerHTML = this.buildResponseOption(3);
    this.responseOptionRows.dataset.responsesCount = 3;
    SurveyFormUtils.showElement(this.responseOptionsBoxTargets[this.activeIndex]);
    SurveyFormUtils.showElement(this.createResponseOptionBtnTargets[this.activeIndex]);
    SurveyFormUtils.showElement(this.toDiscardTargets[this.activeIndex]);
  }
  
  handleOneChoice() {
    this.responseOptionRows.innerHTML = this.buildResponseOption(2);
    this.adaptToOneChoiceResponses();
    SurveyFormUtils.showElement(this.responseOptionsBoxTargets[this.activeIndex]);
    SurveyFormUtils.showElement(this.toDiscardTargets[this.activeIndex]);
  }
  
  handleTextArea() {
    this.responseOptionRows.innerHTML = "";
    this.setToFalseToDiscard(this.activeIndex);
  }

  newSurveyQuestion(e){
    e.preventDefault()

    const content = this.sqTemplateTarget.innerHTML.replace(/SQ_TEMPLATE_RECORD/g, (new Date().getTime().toString() + this.count));
    this.newSurveyQuestionFieldTarget.insertAdjacentHTML('afterbegin', content)
    this.activeIndex = this.count;

    SurveyFormUtils.showElement(this.surveyFields)
    SurveyFormUtils.hideElement(this.sqCardTargets[this.activeIndex])
    this.surveyFields.dataset.newRecord = 'true'

    this.updateIndexTitleNumber()
    this.showOrHideActionButtons()
    this.hideWarningMessages()
    this.toggleDeleteButtons()
    this.disabledFields()
  }

  updateIndexTitleNumber(){
    this.sqIndexTitleTargets[this.activeIndex].textContent = this.activeIndex + 1

    if('newRecord' in this.surveyFields.dataset){
      this.sqIndexTitleNewTargets[this.activeIndex].textContent = this.newSurveyQuestionTextAuxTarget.textContent
    } else {
      this.sqIndexTitleNewTargets[this.activeIndex].textContent = ""
    }
  }

  destroySurveyQuestion(e){
    if(this.activeIndex != null){ return };

    const indexToDestroy = this.sqCardTargets.indexOf(e.target.closest('.card_item'));
    const fieldset = this.sqFieldsetTargets[indexToDestroy];

    this.sqCardTargets[indexToDestroy].remove();

    if('newRecord' in fieldset.dataset){
      fieldset.remove()
    } else {
      fieldset.querySelector("input[name*='_destroy']").value = "1";
      fieldset.querySelectorAll('[data-filter-questions-target]').forEach(childTarget => delete childTarget.dataset.filterQuestionsTarget);
      delete fieldset.dataset.filterQuestionsTarget;
    }

    this.count--;
    this.showOrHideActionButtons();
    this.hideWarningMessages()
  }

  cancelEditForm(){
    if('newRecord' in this.surveyFields.dataset){
      this.newSurveyQuestionFieldTarget.innerHTML = ""
    } else {
      this.surveyFields.classList.add("hidden")
      this.sqCardsListTarget.replaceChild(this.clonedOriginalFieldset, this.surveyFields)
    }

    this.activeIndex = null
    this.toggleDeleteButtons()
    this.showOrHideActionButtons();
  }

  setInitialState(e) {
    if (this.activeIndex !== null) {
      return false;
    }
    this.activeIndex = this.sqCardTargets.indexOf(e.target.closest('.card_item'));
    this.surveyFields.dataset.existingCard = true;
    this.clonedOriginalFieldset = SurveyFormUtils.cloneWithValues(this.surveyFields);
    return true;
  }

  toggleUIElements() {
    this.toggleDeleteButtons();
    this.hideWarningMessages();
    SurveyFormUtils.showElement(this.surveyFields);
    SurveyFormUtils.showElement(this.responseOptionsBoxTargets[this.activeIndex]);
  }

  updateUIAttributes() {
    this.updateIndexTitleNumber();
    this.questionType.setAttribute('disabled', '');
    this.responseOptionRows.dataset.responsesCount = this.responseOptionRows.querySelectorAll('.response-option-row').length;
  }

  handleOptions() {
    this.handleSelectedOptionEdit(this.questionType.value);
    this.showOrHideActionButtons(this.questionType);
  }

  editSurveyQuestion(e) {
    if (!this.setInitialState(e)) {
      return;
    }
    this.toggleUIElements();
    this.updateUIAttributes();
    this.handleOptions();
  }

  handleSelectedOptionEdit(type){
    if(type === 'multiple_choice'){
      this.showOrHideTargetByLimit(this.responseOptionRows.dataset.responsesCount, this.createResponseOptionBtnTargets[this.activeIndex]);
    }else if(type === 'text_area'){
      SurveyFormUtils.hideElement(this.responseOptionsBoxTargets[this.activeIndex])
      this.setToFalseToDiscard(this.activeIndex)
    }else if(type === 'one_choice_as_radio_button'){
      this.adaptToOneChoiceResponses()
    }
  }

  updateSurveyQuestion() {
    SurveyFormUtils.hideElement(this.surveyFields)

    this.updateCardTexts(this.activeIndex)
    this.activeIndex = null
    this.showOrHideActionButtons()
  }

  showOrHideActionButtons(){
    this.showOrHideNewSurveyQuestionBtn()
    this.showOrHideSaveBackBtns()
    this.showOrHideHrDivider()
  }

  updateCardTexts(i){
    this.cardStatementTargets[i].textContent = this.statementTargets[i].value
    this.cardQuestionTypeTargets[i].textContent = this.cardQuestionTypeText(i)
    if(this.isOneChoice() || this.isMultipleChoice()){
      this.cardCorrectOptionTextTargets[i].textContent = this.responseOptionRows.dataset.correctOptionText
      SurveyFormUtils.showElement(this.cardCorrectOptionTargets[this.activeIndex])
    } else {
      this.cardCorrectOptionTextTargets[i].textContent = ""
      SurveyFormUtils.hideElement(this.cardCorrectOptionTargets[this.activeIndex])
    }
  }

  cardQuestionTypeText(i){
    const questionTypeIndex = this.questionType.selectedIndex - 1;
    return this.cardQuestionTypeTextTargets[questionTypeIndex].textContent;
  }

  verifyCorrectOptionText(){
    this.responseOptionRows.querySelectorAll('input[type="checkbox"]').forEach((input, i) => {
      if(input.checked){
        this.responseOptionRows.dataset.correctOptionText = this.responseOptionRows.querySelectorAll('input[type="text"]')[i].value
      }
    })
  }

  hasCorrectOptionChecked(){
    if(this.questionType.value == 'text_area'){
      return true
    } else if('correctOptionText' in this.responseOptionRows.dataset){
      return true
    } else {
      this.responseOptionRows.querySelectorAll('input[type=checkbox]').forEach(input => {
        input.setAttribute('required', true)
      })
      return false
    }
  }

  hasMinimumOptions(){
    if(this.isOneChoice() || this.isTextArea()){ return true }

    return this.responseOptionRows.dataset.responsesCount >= this.responseOptionRows.dataset.responsesMinimum
  }

  isMultipleChoice(){
    return this.questionType.value == 'multiple_choice'
  }

  isOneChoice(){
    return this.questionType.value == 'one_choice_as_radio_button'
  }

  isTextArea(){
    return this.questionType.value == 'text_area'
  }

  adaptToOneChoiceResponses(){
    this.responseOptionRows.querySelectorAll('.remove-on-yes-no').forEach(el => el.remove());
  
    this.responseOptionRows.querySelectorAll('[name*=statement]').forEach((input, i) => {
      input.classList.add('!bg-grey-100', '!border-grey-100', '!text-grey-300', 'cursor-default');
      input.value = this.optionIndexToOneChoiceText(i);
      input.readOnly = true;
    })
  }

  optionIndexToOneChoiceText(i){
    return this.oneChoiceResponseTextTargets[i].textContent
  }

  setToFalseToDiscard(i){
    const optionsDiscard = this.toDiscardTargets[i]
    const falseOption = optionsDiscard.querySelector('input[value="false"]')
    falseOption.checked = true

    SurveyFormUtils.hideElement(optionsDiscard)
  }

  handleCheck(e){
    this.responseOptionRows.querySelectorAll('input[type="checkbox"]').forEach((input, i) => {
      if(input == e.target){
        input.checked = true;
        input.value = true;
      } else {
        input.checked = false;
        input.value = false;
      }
      input.removeAttribute('required');
    });
  }

  createResponseOption(e){
    e.preventDefault();

    this.responseOptionRows.insertAdjacentHTML('beforeend', this.buildResponseOption(1));

    this.responseOptionRows.dataset.responsesCount = this.responseOptionRows.querySelectorAll('.response-option-row').length

    this.showOrHideTargetByLimit(this.responseOptionRows.dataset.responsesCount, this.createResponseOptionBtnTargets[this.activeIndex]);
  }

  destroyResponseOption(e){
    const destroyBtn = e.target.closest('.remove-on-yes-no')
    const responseOption = destroyBtn.closest('.response-option-row')

    if(destroyBtn.previousElementSibling.checked){
      delete this.responseOptionRows.dataset.correctOptionText
    }

    if('newRecord' in responseOption.dataset){
      responseOption.remove()
    } else {
      responseOption.querySelector("input[name*='_destroy']").value = "1";
      responseOption.querySelector("input[type=checkbox]").remove();
      responseOption.classList = 'hidden'
    }

    this.responseOptionRows.dataset.responsesCount = this.responseOptionRows.querySelectorAll('.response-option-row').length

    this.showOrHideTargetByLimit(this.responseOptionRows.dataset.responsesCount, this.createResponseOptionBtnTargets[this.activeIndex]);
  }

  buildResponseOption(quantity){
    let content = '';

    for(let i = quantity; i--;){
      content += this.responseOptionTemplateTargets.at(-1).innerHTML
                  .replace(/SRO_TEMPLATE_RECORD/g, (new Date().getTime().toString() + i))
                  .replace(/-row"/g, '-row" data-new-record="true"')
                  .replace(/placeholder="/, 'placeholder="' +  this.placeholderTargets[i].textContent);
    }
    return content
  }

  showOrHideSaveBackBtns(){
    if(this.activeIndex == null){
      SurveyFormUtils.showElement(this.saveBackBtnsTarget)
    } else {
      SurveyFormUtils.hideElement(this.saveBackBtnsTarget)
    }
  }

  showOrHideNewSurveyQuestionBtn(){
    if((this.activeIndex == null) && (this.count < this.newSurveyQuestionBtnTarget.dataset.limit)){
      SurveyFormUtils.showElement(this.newSurveyQuestionBtnTarget)
    } else {
      SurveyFormUtils.hideElement(this.newSurveyQuestionBtnTarget)
    }
  }

  showOrHideHrDivider(){
    if(this.count == 0){
      SurveyFormUtils.hideElement(this.hrTarget);
    } else {
      SurveyFormUtils.showElement(this.hrTarget);
    }
  }

  showOrHideTargetByLimit(count, target){
    if(count < target.dataset.limit){
      SurveyFormUtils.showElement(target)
    } else {
      SurveyFormUtils.hideElement(target)
    }
  }

  hideCorrectOptionFieldForTextArea(){
    this.questionTypeTargets.forEach((type, i) => {
      if(type.value == 'text_area'){
        SurveyFormUtils.hideElement(this.cardCorrectOptionTargets[i])
      }
    })
  }

  verifyOneQuestionPresent(e){
    if(this.sqCardTargets.length <= 0){
      e.preventDefault()
      SurveyFormUtils.showElement(this.warningMessageTarget)
    }
  }

  hideWarningMessages(){
    SurveyFormUtils.hideElement(this.warningMessageTarget)
  }

  toggleDeleteButtons() {
    this.deleteCardTargets.forEach(element => {
      element.classList.toggle("hidden")
    })
  }

  disabledFields(){
    this.hideToolTip()
    this.handleTextAreaQuestion()
    this.handleToDiscardQuestion()
  }

  handleTextAreaQuestion(){
    const numTextAreaQuestions = Array.from(this.questionTypeTargets)
      .filter(option => option.value === 'text_area')
      .length

    if (numTextAreaQuestions >= 2) {
      this.disableTextAreaQuestion();
    }
  }

  disableTextAreaQuestion(){
    const textAreaOption = this.surveyFields.querySelector('[name*=question_type] option[value="text_area"]')
    const additionalText = this.disabledOptionTextTarget.textContent

    textAreaOption.textContent += additionalText
    textAreaOption.classList.add('text-grey-300')
    textAreaOption.disabled = true
  }


  handleToDiscardQuestion() {
    const numToDiscardActive = Array.from(this.toDiscardTargets)
      .filter(option => option.querySelector('input[value="true"]').checked)
      .length

    if (numToDiscardActive >= 2) {
      this.disableToDiscardQuestion()
    }
  }

  disableToDiscardQuestion() {
    this.showToolTip()
    this.updateToDiscardText()
    this.disableToDiscardFields()
  }

  hideToolTip() {
    SurveyFormUtils.hideElement(this.toolTipTargets[this.activeIndex])
  }

  showToolTip() {
    SurveyFormUtils.showElement(this.toolTipTargets[this.activeIndex])
  }

  updateToDiscardText() {
    const toDiscardText = this.toDiscardTextTargets[this.activeIndex]
    toDiscardText.classList.add('text-grey-300')
  }

  disableToDiscardFields() {
    const fields = this.surveyFields.querySelectorAll('[name*=to_discard]')
    fields.forEach(field => this.disableField(field))
  }

  disableField(field) {
    field.disabled = true
    field.classList.add('opacity-50', 'cursor-not-allowed')
    field.style.background = '#C4C4C4'
  }
}
