import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["educationalLevelSelector", "titleSelector", "valuesEducationalLevel"]
  initialize() {
    this.inputTitle = this.titleSelectorTarget.querySelector('input')
    this.descriptionIds = this.valuesEducationalLevelTarget.dataset.educationallevelids

    if (this.inputTitle.value.trim()) {
      this.titleSelectorTarget.classList.remove('hidden')
      this.inputTitle.required = true
    }
  }

  get educationalLevelSelector() {
    return this.educationalLevelSelectorTarget.value;
  }

  updateSelector(event) {
    let selectEducationalDegree = event.target
    let educationalDegreeValue = selectEducationalDegree.options[selectEducationalDegree.selectedIndex].value
    if (this.descriptionIds.includes(educationalDegreeValue)) {
      this.titleSelectorTarget.classList.add('hidden')
      this.inputTitle.required = false
    } else {
      this.titleSelectorTarget.classList.remove('hidden')
      this.inputTitle.required = true
    }
  }
}
