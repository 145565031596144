import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["validation","toggleCheck"]

  connect(){
    this.toggleTargets()
  }

  toggleTargets() {
    this.element.querySelectorAll('input, select, textarea').forEach((el) => {
      if(this.toggleCheckTarget.checked){
        el.removeAttribute('required')
      }
      else{
        el.setAttribute("required", true)
      }
    });
  }
}
