import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.showContent("Preview");
    this.highlightCode();
  }

  open(event) {
    const tabName = event.currentTarget.dataset.tabsName;
    this.showContent(tabName);
  }

  showContent(tabName) {
    this.contentTargets.forEach((content) => {
      content.classList.toggle("active", content.dataset.tabsName === tabName);
    });
    this.element.querySelectorAll("[data-tabs-name]").forEach((button) => {
      button.classList.toggle("active", button.dataset.tabsName === tabName);
    });

    
    this.highlightCode();
  }

  highlightCode() {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
  }
}
