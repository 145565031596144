import Clipboard from '@stimulus-components/clipboard'

export default class extends Clipboard {
  connect() {
    super.connect()
    this.addButtonEventListeners();
  }

  addButtonEventListeners() {
    const button = this.element;
    const spanCopy = button.querySelector('.copy-span');
    const spanCopied = button.querySelector('.copied-span');

    button.addEventListener('mouseover', () => {
      spanCopy.classList.remove('hidden');
    });

    button.addEventListener('mouseout', () => {
      spanCopy.classList.add('hidden');
    });

    button.addEventListener('click', (event) => {
      spanCopy.classList.add('hidden');
      spanCopied.classList.remove('hidden');
      setTimeout(() => {
        spanCopied.classList.add('hidden');
        spanCopy.classList.remove('hidden');
      }, 2000);
      // Hidden first span next to clic
      setTimeout(() => {
        spanCopy.classList.add('hidden');
      }, 2000);
    });
  }

  disconnect() {
    const button = this.element;
    button.removeEventListener('mouseover', this.handleMouseOver);
    button.removeEventListener('mouseout', this.handleMouseOut);
    button.removeEventListener('click', this.handleClick);
    super.disconnect();
  }

  // Function to override on copy.
  copy() {
    const path = event.currentTarget.closest('[data-url]').dataset.url;
    const domain = window.location.origin;
    const fullPath = domain + path;

    navigator.clipboard.writeText(fullPath)
  }

  // Function to override when to input is copied.
  copied() {
    //
  }
}
