// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "./controllers"
import "chartkick/highcharts"

// This adds a custom class "form-submitted" when the 'submit' button on every form is clicked, this allows styles form input:invalid to be applied
// for reference, check app/assets/stylesheets/components/forms/input.css ".form-submitted"
document.querySelectorAll("form [type=submit]").forEach((element)=>{ element.onclick = (e)=>{ e.target.form.classList.add("form-submitted") } })
