import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["legendEmail", "emailInput"]
  connect() {
    this.validate()
  }
  validate() {
    let emailPattern = /\w+([\.-]?\w+)*(\.\w{2,3})+/
    const labelText = this.legendEmailTarget.innerText
    const result = labelText.match(emailPattern)
    const match = emailPattern.test(labelText)
    if (match) {
      const boton = `<button data-action='suggested-email#replaceSuggestion' class='text-black underline'>${result[0]}</button>`;
      const newContent = labelText.replace(emailPattern, boton)
      this.legendEmailTarget.innerHTML= newContent
    }
  }
  replaceSuggestion(e) {
    e.preventDefault()
    let emailPattern = /@.*$/
    const inputText = this.emailInputTarget.value
    const emailSuggested = "@" + e.target.innerText
    const newContent = inputText.replace(emailPattern, emailSuggested)
    this.emailInputTarget.value = newContent
  }
}
