import { Controller } from "@hotwired/stimulus"
import Imask from "../../libraries/imask.js"

// when use this controller you need user method StringConverter.clear_currency and this clear value of input
export default class extends Controller {

  connect() {
    this.maskInitializer()
  }

  maskInitializer(){
    const inputElement = this.element
    const typeCurrency = this.defineCurrency(inputElement)

    const currencyMaskConfig = this.iMaskConfig(typeCurrency, inputElement)

    const mask = IMask(inputElement, currencyMaskConfig);
  }

  iMaskConfig(typeCurrency, inputElement){
    return {
      mask: this.assignCurrencySimbol(typeCurrency),
      blocks: {
        num: {
          mask: Number,
          min: this.setLimit(inputElement.min),
          max: this.setLimit(inputElement.max),
          thousandsSeparator: ',',
        }
      }
    }
  }

  defineCurrency(input){
    const currency = input.dataset.typecurrency

    if(currency){
      return currency
    } else {
      return 'COP'
    }
  }

  setLimit(value) {
    if (value === "") {
      return null
    } else {
      return value
    }
  }

  assignCurrencySimbol(currency){
    if (currency == 'GBP'){
      return '£num'
    } else {
      return '$num'
    }
  }
}
