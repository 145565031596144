import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="salary"
export default class extends Controller {
  changeSalary(event) {
    let currency_id = event.target.selectedOptions[0].value

    fetch(`/salary_ranges?currency_id=${currency_id}`)
      .then(response => response.text())
      .then(html => {
        const salarySelect = document.querySelector("select#user_salary_range_id")
        salarySelect.innerHTML = html
      })
  }
}
