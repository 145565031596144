import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "form"];

  static values = { shouldSubmit: Boolean };

  connect(){
    this.formElement = this.element.closest('form');
  }

  deselectAll(event) {
    event.preventDefault()
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
    
    if (this.shouldSubmitValue) {
      this.formElement.requestSubmit();
    }
  }
}