import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'filterBtn', 'boxButtons']

  initialize() {
    this.form = this.formTarget
    this.inputs = this.form.querySelectorAll('input[type="text"], input[type="date"], input[type="number"], select')
  }

  cleanForm(event) {
    event.preventDefault()
    this.inputs.forEach(input => input.value = '')
    this.form.submit()
  }
}
