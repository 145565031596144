import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['collectionRadio', 'inputText','btnPrimary', 'btnSecond' ]

  initialize() {
    this.radios = this.collectionRadioTarget.querySelectorAll('input[type=radio]')
  }

  connect() {
    Array.from(this.radios).forEach(elm => elm.addEventListener('change', (event) => this.handleChangeRadios(event))) 
    this.inputTextTarget.addEventListener('change', () => this.handleChangeInput())
  }
  
  handleChangeRadios(event) {
    this.btnSecondTarget.style.display = 'none'
    this.btnPrimaryTarget.style.display = 'block'
  }

  handleChangeInput() {
    const radiosChecked = Array.from(this.radios).filter(elm => (elm.checked))
    if(radiosChecked.length === 0) {
      this.btnPrimaryTarget.style.display = 'none'
      this.btnSecondTarget.style.display = 'block'
    }
  }
}
