import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_object", "template", "remove_object"]
  
  initialize() {
    this.count = this.remove_objectTargets.length
    if(this.add_objectTarget) {
      this.limit = this.add_objectTarget.dataset.limit

      if(this.count == this.limit) {
        this.add_objectTarget.style.display = "none"
      }
    }
  }

  add_association(event) {
    event.preventDefault()
    if(this.limit) {
      this.count++
      if(this.count <= this.limit ) {
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
        this.add_objectTarget.insertAdjacentHTML('beforebegin', content)
      }
  
      if(this.count == this.limit) {
        this.add_objectTarget.style.display = "none"
      }
    }else {
      let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
      this.add_objectTarget.insertAdjacentHTML('beforebegin', content)
    }
  }

  remove_association(event) {    
    event.preventDefault()
    if(this.limit) { 
      this.count--
      if(this.count <= this.limit) {
        this.add_objectTarget.style.display = "block"
      }
    }
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.querySelectorAll('input, select, textarea').forEach(element => {
      element.removeAttribute("required")
    })
    item.style.display = 'none'
  }

  count_fields() {
    return this.remove_objectTargets.length
  }
}
