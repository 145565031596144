import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'globalInput', 'submitButton',
    'presentialDateInput', 'presentialHourInput', 'presentialContactInput', 'presentialAddressInput', 'presentialAdditionalRefsInput',
    'virtualDateInput', 'virtualHourInput', 'virtualContactInput', 'virtualMeetLinkInput',
    'callDateInput', 'callHourFromInput', 'callHourToInput'
  ]

  handleTypeCitation(event){
    this.availableSubmitButton()
    this.removeAttributeRequiredInputs()

    const citationType = event.target.value

    switch(citationType){
      case 'presential':
        this.presentialDateInputTarget.setAttribute('required', true)
        this.presentialHourInputTarget.setAttribute('required', true)
        this.presentialContactInputTarget.setAttribute('required', true)
        this.presentialAddressInputTarget.setAttribute('required', true)
        break;
      case 'virtual':
        this.virtualDateInputTarget.setAttribute('required', true)
        this.virtualHourInputTarget.setAttribute('required', true)
        this.virtualContactInputTarget.setAttribute('required', true)
        this.virtualMeetLinkInputTarget.setAttribute('required', true)
        break;
      case 'call':
        this.callDateInputTarget.setAttribute('required', true)
        this.callHourFromInputTarget.setAttribute('required', true)
        this.callHourToInputTarget.setAttribute('required', true)
        break;
    }

    const popUp = event.target.closest('.popup-content');
    this.adjustScroll(citationType, popUp)
  }

  adjustScroll(citationType, popUp){
    if(citationType == 'presential'){
      popUp.style.paddingTop = '12rem';
    } else {
      popUp.style.height = 'fit-content';
      popUp.style.paddingTop = null;
    }
  }

  removeAttributeRequiredInputs(){
    this.globalInputTargets.forEach(element => {
      element.removeAttribute('required')
    })
  }

  availableSubmitButton(){
    this.submitButtonTarget.classList.remove('hidden')
  }
}
