import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.scrollToTop(event)
    document.addEventListener("turbo:frame-load", this.scrollToTop.bind(this))
  }

  scrollToTop(event) {
    if (event && event.target.id !== "offer_details") return
    if (this.hasContainerTarget) {
      this.containerTarget.scrollTop = 0
    }
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.scrollToTop.bind(this))
  }
}

