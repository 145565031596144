import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["details", "buttonSubmit"]

  closeDetails() {
    this.detailsTarget.removeAttribute('open')
  }

  disableButton(event) {
    event.preventDefault()
  const form = this.buttonSubmitTarget.closest('form')
    if(form.checkValidity()) {
      this.buttonSubmitTarget.disabled = true
      form.submit()
    }
  }
}
