import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formHours", "selectStartHour", "selectEndHour", "checkBoxFullTime"]
  connect() {
    this.formVisibilityId = this.formHoursTarget.dataset.formVisibilityId
    this.containerFormHours = this.element.querySelector(`#working_day-${this.formVisibilityId}`)
    this.startHour = this.element.querySelector(`#start_hour_working_day-${this.formVisibilityId}`)
    this.endHour = this.element.querySelector(`#end_hour_working_day-${this.formVisibilityId}`)
    const checkBoxFullTime = this.element.querySelector(`#check_box_working_day-${this.formVisibilityId}`)
    this.validateShowFullTime(checkBoxFullTime)
  }

  validateShowFullTime(fullTime){
    if (fullTime.checked){
      this.containerFormHours.classList.remove("hidden")
    } else {
      this.containerFormHours.classList.add("hidden")
    }
  }

  setAttributes(event){
    if (event.target.id === `check_box_working_day-${this.formVisibilityId}`){
      if (event.target.checked){
        this.containerFormHours.classList.remove("hidden")
        this.startHour.setAttribute('required', true)
        this.endHour.setAttribute('required', true)
      } else {
        this.containerFormHours.classList.add("hidden")
        this.startHour.removeAttribute('required')
        this.endHour.removeAttribute('required')
      }
    }
  }

  disable(){
    this.element.hidden = true
    this.startHour.removeAttribute('required')
    this.endHour.removeAttribute('required')
  }

  enable(){
    this.element.hidden = false
  }
}
