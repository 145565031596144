import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "skillsContainer", "tagsContainer", "hiddenInputs"];
  static values = { endpointList: String, locale: String, closeImage: String, messageBlank: String };

  showOptions(event) {
    let inputValue = this.inputTarget.value;
    let containerElement = this.skillsContainerTarget;

    this.clearContainers();

    if (inputValue.length >= 3) {
      this.fetchSkills(inputValue);
    } else {
      this.hideSkillsContainer();
    }
  }

  clearContainers() {
    this.skillsContainerTarget.innerHTML = '';
  }

  fetchSkills(inputValue) {
    fetch(`/${this.endpointListValue}?description=${inputValue}&locale=${this.localeValue}`)
      .then(response => response.json())
      .then(data => {
        this.handleSkillsData(data);
      })
      .catch(err => {});
  }

  handleSkillsData(data) {
    if (data.length === 0) {
      this.showNoSkillsFoundMessage();
    } else {
      this.showSkillsContainer();
      data.forEach(element => {
        this.createCheckbox(element[0], element[1]);
      });
    }
  }

  showNoSkillsFoundMessage() {
    this.clearContainers();
    const message = document.createElement('p');
    
    message.textContent = this.messageBlankValue;
    this.skillsContainerTarget.appendChild(message);
    this.showSkillsContainer();
  }

  showSkillsContainer() {
    this.skillsContainerTarget.classList.remove("hidden");
    this.skillsContainerTarget.classList.remove("lg:hidden");
    this.skillsContainerTarget.classList.remove("xl:hidden");
  }

  clearContainers() {
    this.skillsContainerTarget.innerHTML = '';
  }  

  hideSkillsContainer() {
    this.skillsContainerTarget.classList.add("hidden");
    this.skillsContainerTarget.classList.add("lg:hidden");
    this.skillsContainerTarget.classList.add("xl:hidden");
  }

  createCheckbox(id, text) {
    const checkbox = this.createCheckboxElement(id);
    const label = this.createLabelElement(checkbox, text);

    checkbox.addEventListener('change', () => {
      this.handleCheckboxChange(checkbox, text, id);
    });

    this.skillsContainerTarget.appendChild(label);
  }

  createCheckboxElement(id) {
    const checkbox = document.createElement('input');
    checkbox.classList.add('mr-2');
    checkbox.type = 'checkbox';
    checkbox.name = 'soft_skill_ids[]';
    checkbox.value = id;

    return checkbox;
  }

  createLabelElement(checkbox, text) {
    const label = document.createElement('label');
    label.classList.add('flex');
    label.appendChild(checkbox);
    label.appendChild(document.createTextNode(text));

    return label;
  }

  handleCheckboxChange(checkbox, text, id) {
    const tag = this.findTag(id);
    const hiddenInput = this.findHiddenInput(id);

    if (checkbox.checked) {
      this.handleCheckboxChecked(tag, hiddenInput, text, id);
    } else {
      this.handleCheckboxUnchecked(tag, hiddenInput, checkbox);
    }
  }

  handleCheckboxChecked(tag, hiddenInput, text, id) {
    if (!tag) {
      this.addTag(text, id);
    }

    if (!hiddenInput) {
      this.addHiddenInput(id);
    }
  }

  handleCheckboxUnchecked(tag, hiddenInput, checkbox) {
    if (tag) {
      tag.remove();
    }

    if (hiddenInput) {
      hiddenInput.remove();
    }

    checkbox.checked = false;
  }

  addTag(text, id) {
    const tag = document.createElement('label');
    tag.classList.add('tag', '--green', 'ml-2', 'mt-2');
    tag.textContent = text;
    tag.dataset.id = id;

    const icon = document.createElement('img');
    icon.src = this.closeImageValue;
    icon.alt = 'Close';
    icon.classList.add('close-icon');
    icon.addEventListener('click', () => this.removeTag(tag, id));
    tag.appendChild(icon);

    this.tagsContainerTarget.appendChild(tag);
  }

  addHiddenInput(id) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'user[soft_skill_hidden_ids][]';
    hiddenInput.value = id;
    hiddenInput.dataset.hiddenFor = id;

    this.hiddenInputsTarget.appendChild(hiddenInput);
  }

  removeTag(tag, id) {
    const text = tag.textContent;
    tag.remove();

    const hiddenInput = this.findHiddenInput(id);
    if (hiddenInput) {
      hiddenInput.remove();
    }

    this.skillsContainerTarget.querySelector(`input[value="${id}"]`).checked = false;
  }

  findTag(id) {
    return this.tagsContainerTarget.querySelector(`label[data-id="${id}"]`);
  }

  findHiddenInput(id) {
    return this.hiddenInputsTarget.querySelector(`input[data-hidden-for="${id}"]`);
  }
}

