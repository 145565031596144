import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hideable", "input", "toggleCheck", "hideableDiscard", "blankField", "state", "city", "checkState", "inputRequired"]

  connect() {
    this.discarded = 'discarded'
    this.removeInitRequired()
  }

  removeInitRequired() {
    this.inputRequiredTargets.forEach((input) => {
      const validateAttr = input.hasAttribute('required')

      if(validateAttr) {
        input.removeAttribute('required')
      }
    })
  }

  toggleTargets(event) {
    this.hideTargets(event.target.id)
    this.toggleRequiredState()
  }

  toggleCountryTargets(event) {
    this.toggleTargets(event)
    this.uncheckedState()
    !this.stateTarget.getAttribute('required') && this.removeRequiredCity()
  }

  hideTargets(targetId) {
    const targets = targetId === this.discarded ? this.hideableDiscardTargets : this.hideableTargets
    targets.forEach((el) => {
      el.hidden = !el.hidden
      el.value = !el.hidden
    })
  }

  uncheckedState(event) {
    this.checkStateTarget.checked = false
  }

  toggleRequiredState() {
    this.inputTargets.forEach((input) => {
      const validateAttr = input.hasAttribute('required')

      validateAttr ? input.removeAttribute('required') : input.setAttribute('required', true)
    })

    this.blankFieldTargets.forEach((field) => {
      field.value = ""
    })
  }

  toggleRequiredStateCopy() {
    this.cityTarget.hasAttribute('required') ? this.removeRequiredCity() : this.cityTarget.setAttribute('required', true)
  }

  removeRequiredCity() {
    this.cityTarget.removeAttribute('required')
  }

  validateHiddenField() {
    if(this.toggleCheckTarget.checked) {
      this.blankFieldTargets.forEach((input) => {
        input.setAttribute('required', true)
      })
    } else {
      this.blankFieldTargets.forEach((input) => {
        input.removeAttribute('required')
      })
    }
  }
}
