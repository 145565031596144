import { Controller } from "@hotwired/stimulus"

const delay = 30 // delay time to make checkbox requests

export default class extends Controller {
  static targets = ["main", "item"]

  async toggle(event) {
    const checked = event.target.checked

    this.itemTargets.forEach(item => {
      item.checked = checked
    })

    this.itemTargets.forEach(async (item, index) => {
      await this.triggerChangeWithDelay(item, delay * index);
    })
  }

  async triggerChangeWithDelay(item, delay) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const changeEvent = new Event('change', { bubbles: true })
        item.dispatchEvent(changeEvent)
        resolve()
      }, delay)
    })
  }
}
