import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

	unCheckedBoxes(event){
	  event.preventDefault()
	  this.checkboxTargets.forEach( checkbox => checkbox.checked = false)
	}
}
