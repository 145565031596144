import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "popup", "tags", "select", "arrow"]

  connect() {
    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        this.popupTarget.classList.add('hidden')
      }
    })
  }

  togglePopup(event) {
    event.preventDefault()
    this.popupTarget.classList.toggle('hidden')
    this.arrowTarget.classList.toggle('rotate-180')
  }

  addTag(label, value) {
    const tag = document.createElement('span')
    tag.className = 'tag --blue'
    tag.textContent = label
    tag.dataset.value = value

    // Create the remove button
    const removeButton = document.createElement('button')
    removeButton.className = 'ml-2 rounded-full text-red-500 text-white p-0.5 text-lg'
    removeButton.textContent = '×'
    removeButton.type = 'button'

    // Event listener for the remove button
    removeButton.addEventListener('click', () => {
        this.removeTag(value)
        //  uncheck the corresponding checkbox
        this.checkboxTargets.find(cb => cb.value === value).checked = false
    })

    tag.appendChild(removeButton)
    // Append the tag to the tags container
    this.tagsTarget.appendChild(tag)
  }

  removeTag(value) {
    const tags = this.tagsTarget.querySelectorAll('span')
    tags.forEach(tag => {
      if (tag.dataset.value === value) {
        tag.remove()
      }
    })
  }

  updateSelect(event) {
    const checkbox = event.target
    const label = checkbox.nextElementSibling.textContent
    const value = checkbox.value

    if (checkbox.checked) {
      this.addTag(label, value)
    } else {
      this.removeTag(value)
    }
    this.updateSelectOptions()
  }

  updateSelectOptions() {
    const selectedValues = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)

    this.selectTarget.innerHTML = ''

    selectedValues.forEach(value => {
      const option = document.createElement('option')
      option.value = value
      option.selected = true
      this.selectTarget.appendChild(option)
    })
  }
}
