import { Controller } from "@hotwired/stimulus";

export default class ModalTriggerController extends Controller {
  static values = {
    modalId: String
  }

  openModal(event) {
    const modalId = this.modalIdValue

    document.dispatchEvent(new CustomEvent("modal:open", {
      detail: { modalId }
    }));
  }

  closeModal(event) {
    event.preventDefault()
    const modalId = this.modalIdValue
    document.dispatchEvent(new CustomEvent("modal:close", {
      detail: { modalId }
    }));
  }
}
