import { Controller } from "@hotwired/stimulus";
import cytoscape from "cytoscape";

export default class extends Controller {
  connect() {
    this.cy = this.initializeCytoscape();
    this.setupNodeEvents();
  }

  createJson() {
    const nodes = this.cy.nodes().map(node => ({
      id: node.id(),
      label: node.data('label')
    }));

    const edges = this.cy.edges().map(edge => ({
      id: edge.id(),
      source: edge.source().id(),
      target: edge.target().id(),
      label: edge.data('label')
    }));

    const graphData = {
      nodes, edges
    };

    const jsonData = JSON.stringify(graphData);

    fetch(`/crm/flows`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: jsonData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al guardar el gráfico');
        }
        return response.json();
      })
      .then(data => {
        console.log('Gráfico guardado exitosamente:', data);
        // Realizar acciones adicionales si es necesario
      })
      .catch(error => {
        console.error('Error al guardar el gráfico:', error);
        // Manejar el error de alguna manera
      });
  }

  initializeCytoscape() {
    return cytoscape({
      container: this.element,
      elements: [{ data: { id: 'node1', label: 'Primer mensaje' } }],
      style: [
        { selector: 'node', style: { 'label': 'data(label)' } },
        {
          selector: 'edge',
          style: {
            'curve-style': 'bezier',
            'control-point-step-size': 20,
            'target-arrow-shape': 'triangle'
          }
        },
      ],
      layout: { name: 'preset' },
      minZoom: 0.7,
      maxZoom: 2
    });
  }

  setupNodeEvents() {
    if (this.cy) {
      this.cy.on('tap', 'node', (event) => {
        this.createEdge(event.target.id());
      });
    }
  }

  createEdge(nodeId) {
    if (this.element.querySelector('form')) {
      return;
    }

    const formContainer = this.createFormContainer(nodeId);
    this.element.appendChild(formContainer);
    const form = formContainer.querySelector('form');

    form.addEventListener('submit', (event) => {
      event.preventDefault();
      const targetNodeId = form.targetNode.value;
      const edgeId = `edge_${nodeId}_${targetNodeId}_${Date.now()}`;

      this.cy.add({
        group: 'edges',
        data: { id: edgeId, source: nodeId, target: targetNodeId },
        style: { 'label': form.label.value }
      });

      formContainer.remove();
    });
  }

  createFormContainer(nodeId) {
    const formContainer = document.createElement('div');
    formContainer.innerHTML = `
      <form class="bg-white flex flex-col gap-2 w-1/2 p-4" id="edgeForm" data-node-id="${nodeId}">
        <label for="label">Label:</label>
        <input type="text" name="label" value="">
        <label for="targetNode">Nodo Destino:</label>
        <select name="targetNode" id="targetNode">
          ${this.generateNodeOptions()}
        </select><br>
        <button class='btn --primary max-w-max' type="submit">Guardar</button>
      </form>`;

    return formContainer;
  }

  generateNodeOptions() {
    let options = '';
    this.cy.nodes().forEach(node => {
      options += `<option value="${node.id()}">${node.data().label}</option>`;
    });
    return options;
  }

  addNode() {
    if (this.element.querySelector('form')) {
      return;
    }

    const newNode = this.cy.add({
      data: { id: 'node-' + Date.now(), label: 'nuevo Nodo' },
    });

    const formContainer = document.createElement('div');
    formContainer.innerHTML = `
      <form class="bg-white flex flex-col gap-2 w-1/2 p-4" data-action="submit->diagram#updateNode" data-node-id="${newNode.id()}">
        <label for="label">Label:</label>
        <input type="text" name="label" value="nuevo nodo">
        <button type="submit" class='btn --primary max-w-max'>Guardar</button>
      </form>`;

    this.element.appendChild(formContainer);
  }

  updateNode(event) {
    event.preventDefault();
    const form = event.target;
    const label = form.querySelector('input[name="label"]').value;
    const node = this.cy.getElementById(form.getAttribute('data-node-id'));

    if (node) {
      node.data('label', label);
    }
    form.remove();
  }
}
