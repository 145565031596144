import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  toggle(){
    if(this.menuTarget.classList.contains('hidden')) {
      this.toggleMenuClasses()
      this.hideInactiveFilters()
    } else {
      this.resetFiltersBtns()
    }
    this.handleSuperParentOverflow()
  }

  hide(event){
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.resetFiltersBtns()
      this.handleSuperParentOverflow()
    }
  }

  hideInactiveFilters(){
    if(window.screen.availWidth > 1308){ return } // dont hide on desktop

    this.filtersBtns.forEach((btn) => {
      if(!btn.classList.contains('active')){
        btn.style.display = 'none'
      }
    })
  }

  handleSuperParentOverflow(){
    // To find the super-parent element outside of scope and fix a visual interaction with the horizontal scrolling 
	  const superParent = this.menuTarget.closest('.super-parent')
    superParent.classList.toggle('overflow-x-auto')
    superParent.classList.toggle('overflow-y-hidden')
  }
 
  resetFiltersBtns(){
    this.toggleMenuClasses()
    this.filtersBtns.forEach(btn => btn.style.display = 'block')
  }

  toggleMenuClasses(){
    this.menuTarget.classList.toggle('hidden')
    this.menuTarget.parentNode.classList.toggle('active')
  }

  get filtersBtns(){
    return Array.from(this.menuTarget.parentNode.parentNode.children)
  }
}
