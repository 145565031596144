import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unhide", "openEye", "closedEye"]

  password() {
    this.openEyeTarget.classList.toggle('hidden')
    this.closedEyeTarget.classList.toggle('hidden')

    if (this.unhideTarget.type === "password") {
      this.unhideTarget.type = "text";
    } else {
      this.unhideTarget.type = "password";
    }
  }
}
