import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['short', 'full', 'button', 'element', 'elementWithClass']
  static values = { isOpen: Boolean, default: false}
  
  initialize() {
    if (this.isOpenValue) {
      this.toggleElements();
    }
  }

  toggle() {
    this.shortTarget.classList.toggle("hidden")
    this.fullTarget.classList.toggle("hidden")
    this.buttonTarget.classList.toggle("hidden")
  }

  toggleElements() {
    this.elementTargets.forEach(elm => elm.classList.toggle('hidden'))
    this.toggleElementsWhitClass()
  }

  toggleElementsWhitClass() {
    if (this.hasElementWithClassTarget) this.elementWithClassTarget.classList.toggle(this.elementWithClassTarget.dataset.classname)
  }
}
