import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["baseCircle"]

  connect() {
    this.calculateDistances()
  }

  calculateDistances() {
    const baseCircleElements = this.baseCircleTargets

    const distances = []

    for (let i = 1; i < baseCircleElements.length; i++) {
      const prevElement = baseCircleElements[i - 1]
      const currentElement = baseCircleElements[i]
      const distance = currentElement.offsetLeft - prevElement.offsetLeft
      distances.push(distance)
    }

    const averageDistance = (distances.reduce((acc, distance) => acc + distance, 0) / distances.length) - 10

    document.documentElement.style.setProperty("--distance", `${averageDistance}px`)
  }
}
