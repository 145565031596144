import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["companyName", "description", "jobCategory", "workFunction", "startedAt", "finishedAt", "input", "loadSection", "suggestedSection", "totalSection", "stillInProgress"]

  submitForm(event) {
    event.preventDefault()

    this.totalSectionTarget.classList.remove('hidden')

    const [entryMonth, entryYear] = this.startedAtTargets
    const [departureMonth, departureYear] = this.finishedAtTargets

    const formValues = {
      company_name: this.companyNameTarget.value,
      description: this.descriptionTarget.value,
      job_category_id: this.jobCategoryTarget.value,
      work_function: this.workFunctionTarget.value,
      started_at: `01/${entryMonth.value}/${entryYear.value}`,
      finished_at: `01/${departureMonth.value}/${departureYear.value}`,
      still_in_progress: this.stillInProgressTarget.checked
    }

    fetch(`/openai/suggested_descriptions_by_candidate/get_suggestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formValues),
    })
      .then(response => response.json())
      .then(data => {
        this.inputTarget.name = this.workFunctionTarget.name
        this.inputTarget.value = data['response']
        if(this.inputTarget.value.length > 10) {
          this.loadSectionTarget.classList.add('hidden')
          this.suggestedSectionTarget.classList.remove('hidden')
          this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`
        }
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }
}
