import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['option1', 'option2', 'option3', 'input1', 'input2', 'input3', 'loadSection', 'suggestedSection']

  connect() {
    this.totalOptions = []
    this.offerId = this.element.dataset.offerId

    const targetNames = ['option1', 'input1', 'option2', 'input2', 'option3', 'input3']

    for (let i = 0; i < targetNames.length; i += 2) {
      const optionTarget = targetNames[i]
      const labelTarget = targetNames[i + 1]

      this.loadOptionData(optionTarget, labelTarget);
    }
  }

  loadOptionData(optionName, labelName) {
    fetch(`/openai/suggested_descriptions/${this.offerId}`)
      .then(response => response.json())
      .then(data => {
        this.totalOptions.push(labelName)
        this[`${optionName}Target`].value = data['response'];
        this[`${labelName}Target`].value = data['response'];

        if(this.totalOptions.length == 3) {
          this.loadSectionTarget.classList.add('hidden')
          this.suggestedSectionTarget.classList.remove('hidden')
          this.totalOptions.map(option => {
            this[`${option}Target`].style.height = `${this[`${option}Target`].scrollHeight}px`
          })
        }
      })
  }
}
