import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["endDateField",  "startDateField"]

    get startDateReturn() {
        return this.startDateFieldTarget
    }

    get endDateReturn() {
        return this.endDateFieldTarget
    }

    getDatesElements(element, selector) {
        return {
            yearSelect: element.querySelector(`#${selector}_1i`),
            monthSelect: element.querySelector(`#${selector}_2i`)
        }
    }

    validationMonthDate(event) {
        const element = event.target.id.includes('start') ? this.startDateReturn : this.endDateReturn
        const labelSelector = element.querySelector('label').getAttribute('for')
        const { yearSelect, monthSelect } = this.getDatesElements(element, labelSelector)

        if(event.target.id === `${labelSelector}_1i`){
            let optionsMonths = []
            let actualMonth = new Date().getMonth() + 1
            const validateYear = yearSelect.value === new Date().getFullYear().toString()
            const defaultOption = monthSelect.value <= actualMonth ? monthSelect.value : actualMonth
            optionsMonths = this.extractOptionsMap(monthSelect, actualMonth, validateYear)
            validateYear && defaultOption && optionsMonths[defaultOption].setAttribute('selected', "")
            monthSelect.innerHTML = ''
            monthSelect.append(...optionsMonths)
        }
    }

    extractOptionsMap(monthSelect, actualMonth, validateYear) {
        const monthSelectOptions = [...monthSelect.options]
        return monthSelectOptions.map(option => {
            const optionElement = document.createElement('option')
            if(option.value > actualMonth && validateYear){
                this.setBasicFields(option, optionElement, "")
                this.setAttributesField(false, optionElement, 'disabled', "")
            }else if(!option.value && option.textContent !== monthSelectOptions[0].textContent){
                this.setBasicFields(option, optionElement, ++actualMonth)
                optionElement.removeAttribute('disabled')
            }else{
                this.setBasicFields(option, optionElement, option.value)
                if(option.value == monthSelect.value) this.setAttributesField(false, optionElement, 'selected', "")
            }
            return optionElement
        })
    }

    setBasicFields(option, optionElement, value){
        this.setAttributesField(option, optionElement, 'value', value)
        optionElement.textContent = option.textContent
    }

    setAttributesField(option, optionElement, param, value){
        optionElement.setAttribute(param, value)
    }
}