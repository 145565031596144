import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.calculateDistance()

    window.addEventListener('resize', () => {
      this.calculateDistance()
    })
  }

  calculateDistance() {
    const elements = this.element.querySelectorAll('.base-circle-icon')
    const firstElement= elements[0].getBoundingClientRect()
    const secondElement = elements[1].getBoundingClientRect()

    const distanciaEnPixeles = secondElement.left - firstElement.right

    const lines = document.querySelectorAll('.line');
    lines.forEach(line => {
      line.style.setProperty('--distance', `${distanciaEnPixeles}px`);
    });
  }
}
