import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["section"]

  connect() {
    this.startSections()
  }

  startSections() {
    const section = this.sectionTargets.find(section => {
      return section.querySelectorAll("input[type='checkbox']:checked").length > 0
    })

    if(section) {
      this.updateSectionState(section)
    }
  }

  toggleSection(event) {
    const currentSection = event.target.closest("[data-deselect-target='section']")
    this.updateSectionState(currentSection)
  }

  updateSectionState(section) {
    const isAnyCheckedInCurrentSection = section.querySelectorAll("input[type='checkbox']:checked").length > 0

    this.sectionTargets.forEach((targetSection) => {
      if (targetSection !== section) {
        if (isAnyCheckedInCurrentSection) {
          targetSection.classList.add('pointer-events-none', 'opacity-50')
        } else {
          targetSection.classList.remove('pointer-events-none', 'opacity-50')
        }
      }
    })

    if (!isAnyCheckedInCurrentSection) {
      this.removeClass()
    }
  }

  removeClass() {
    this.sectionTargets.forEach((targetSection) => {
      targetSection.classList.remove('pointer-events-none', 'opacity-50')
    })
  }
}
