import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["templateFileUploadField", "containerAction"]

  addTemplate(event) {
    const mapObj = {
      attachment: new Date().getTime(),
      mt: "mt-4"
    }

    const template = this.templateFileUploadFieldTarget.innerHTML.replace(/\b(?:attachment|mt)\b/gi, matched => mapObj[matched])

    this.containerActionTarget.insertAdjacentHTML('beforebegin', template)
  }
}
