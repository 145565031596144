import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    modalId: String
  }

  openModal(event) {
    const modalId = this.modalIdValue

    document.dispatchEvent(new CustomEvent("modal-event:open-modal", {
      detail: { modalId }
    }));
  }

  closeModal(event) {
    event.preventDefault()
    const modalId = this.modalIdValue
    document.dispatchEvent(new CustomEvent("modal-event:close-modal", {
      detail: { modalId }
    }));
  }
}
