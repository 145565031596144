import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    let newData = {
      currency: this.element.dataset.currency,
      value: '1000',
      item_id: this.element.dataset.itemId,
      item_name: this.element.dataset.itemName,
      item_category: this.element.dataset.itemCategory
    };
    
    if(this.element.dataset.transactionId){
      newData.transaction_id = this.element.dataset.transactionId 
      newData.items = JSON.parse(this.element.dataset.items)
    }

    window.dataLayer[0] = Object.assign(dataLayer[0], newData);
  }
}
