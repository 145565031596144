import { Controller } from "@hotwired/stimulus";

const MAX_TRANSITION_DURATION_MS = 200; // This is used to display exit animations
const MINIMUM_DRAGGABLE_DISTANCE = -10; // This is the minimum movement required to start dragging

export default class extends Controller {
  static targets = ["wrapper", "close", "container", "draggable"];
  static values = { startPosY: Number, dragDistance: Number, autoShow: Boolean};


  connect() {
    document.addEventListener("modal:open", this.openFromEvent.bind(this));
    document.addEventListener("modal:close", this.closeFromEvent.bind(this));

    // Automatically show the modal if the auto-show value is true
    if (this.autoShowValue) {
      this.showModal(new Event('auto-show'));
    }
  }

  closeTargetConnected(el) {
    el.addEventListener("click", (event) => this.hideModal(event));
  }

  draggableTargetConnected(el) {
    el.ontouchstart = (ev) => this.dragModal(ev);
    el.ontouchmove = (ev) => this.moveModal(ev);
    el.ontouchend = (ev) => this.stopDragModal(ev);
  }

  dragModal(e) {
    this.startPosY = e.targetTouches[0].pageY;

    // This transition overrides the default transition that is used when the user
    // closes the modal by clicking the button or clicking outside
    this.containerTarget.style.transition = "linear 100ms";
  }

  moveModal(e) {
    this.dragDistance = this.startPosY - e.targetTouches[0].pageY;

    if (this.dragDistance <= MINIMUM_DRAGGABLE_DISTANCE) {
      this.containerTarget.style.transform = `translateY(${Math.abs(
        this.dragDistance
      )}px)`;
    }
  }

  stopDragModal() {
    this.containerTarget.style.transform = "";
    this.containerTarget.style.transition = "";

    // We will close the modal only if the dragDistance (the amount we are moving the modal in px)
    // is bigger than half of the modal height.
    if (
      this.dragDistance <= MINIMUM_DRAGGABLE_DISTANCE &&
      Math.abs(this.dragDistance) > this.containerTarget.clientHeight / 3
    ) {
      this.hideModal();
    }

    this.startPosY = 0;
    this.dragDistance = 0;
  }

  showModal(event) {
    event.preventDefault()
    document.body.classList.add("overflow-hidden");
    this.wrapperTarget.classList.add("--show");
  }

  hideModal(event) {
    event.preventDefault()
    document.body.classList.remove("overflow-hidden");

    if (this.wrapperTarget.classList.contains("--show")) {
      this.wrapperTarget.classList.add("--exit");

      setTimeout(() => {
        this.wrapperTarget.classList.remove("--exit", "--show");
      }, MAX_TRANSITION_DURATION_MS);
    }
  }

  disconnect() {
    document.removeEventListener("modal:open", this.openFromEvent.bind(this));
    document.removeEventListener("modal:close", this.closeFromEvent.bind(this));
  }

  openFromEvent(event) {
    const { modalId } = event.detail;
    if (this.element.id === modalId) {
      this.showModal(new Event('custom-open'));
    }
  }
  
  closeFromEvent(event) {
    const { modalId } = event.detail;
    if (this.element.id === modalId) {
      this.hideModal(new Event('custom-close'));
    }
  }
}
