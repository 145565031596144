import { Controller } from "@hotwired/stimulus";

LOCAL_STORAGE_KEY = "is_sidebar_minimized";
MINIMIZED_CLASS = "--minimized";
ANIMATED_CLASS = "--animated";

export default class extends Controller {
  static targets = ["sidebar", "pageContent"];
  static values = { static: Boolean };

  connect() {
    const isMinimized = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (isMinimized || this.staticValue) {
      this.sidebarTarget.classList.add(MINIMIZED_CLASS);
      this.pageContentTarget.classList.add(MINIMIZED_CLASS);
    }

    if (this.staticValue) {
      this.sidebarTarget.onmouseenter = () => this.toggleShrink();
      this.sidebarTarget.onmouseleave = () => this.toggleShrink();
    }

    // We add the animation classes here to avoid triggering an animation
    // when loading the page (since we are using localStorage to set the initial state)
    setTimeout(() => {
      this.sidebarTarget.classList.add(ANIMATED_CLASS);
      this.pageContentTarget.classList.add(ANIMATED_CLASS);
    }, 100);
  }

  toggleShrink() {
    this.sidebarTarget.classList.toggle(MINIMIZED_CLASS);
    this.pageContentTarget.classList.toggle(MINIMIZED_CLASS);

    if (!this.staticValue) {
      const isMinimized = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      localStorage.setItem(LOCAL_STORAGE_KEY, !isMinimized);
    }
  }
}
