import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // This controller updates the browser's history based on the connected HTML element's data.
  connect(){
    this.updateBrowserHistory();
  }

  updateBrowserHistory() {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('id', this.data.get('id'));
    window.history.pushState({}, document.title, newUrl.toString());
  }
}
