import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form_other_cities", "template", "add_object", "template_card", "add_card_object", "check_accepts_cities", "wrapper_other_cities", "checkbox_accept_candidates_by_state"]
  
  initialize() {
    this.getDefaultStates()
    this.getStates()
    this.toogleShowForm()
    this.toogleShowAdditionalCities()

    this.count = (this.element.querySelectorAll(".nested-fields") || []).length;
    this.limit = this.add_objectTarget.dataset.limit
    
    let lastCard = Array.from(this.element.querySelectorAll('.card_item')).pop();
    
    if(!lastCard.getAttribute("data-offercity-id")) {
      lastCard.style.display = "none"
    }

    if (this.count == this.limit) this.add_objectTarget.style.display = "none"
  }

  addAssociation(event) {
    event.preventDefault()
    if(this.validateForm()) {
      if (this.count <= this.limit) {
        const currentForm = this.getCurrentForm()

        // Card
        if(!currentForm.getAttribute("data-offercity-id-fields")){
          const card = this.template_cardTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
          this.add_card_objectTarget.insertAdjacentHTML('beforeend', card)
          this.fillDataCard()  
          currentForm.hidden = true
        }
        
        // Form
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
        this.add_objectTarget.insertAdjacentHTML('beforebegin', content)
        
        this.getStates()
        this.getCitiesSelected()
      }

      if (this.count == this.limit) {
        let currentForm = this.getCurrentForm()
        currentForm.remove()
        this.add_objectTarget.style.display = "none"
      }

      this.count++
    }
  }

  validateForm(){
    const currentForm = this.getCurrentForm()
    const requiredFields = currentForm.querySelectorAll("select")

    // Check if all required fields are filled
    const allFieldsFilled = [...requiredFields].every(field => field.value.trim() !== '')

    if (allFieldsFilled) {
      // Submit the form if all required fields are filled
      return true
    } else {
      // Focus on the first input that has not been filled
      for (const field of requiredFields) {
        if (field.value.trim() === '') {
          field.focus()
          break
        }
      }
    }
  }

  fillDataCard() {
    const cards = this.element.querySelectorAll(".card_item")
    const currentCard = cards[cards.length - 1]
    currentCard.classList.remove("hidden")

    const {state_name, city_name, city_id}  = this.getDataForm()

    const idCity = currentCard.querySelector('[data-name="id-city"]');
    idCity.value = city_id

    const labelStateName = currentCard.querySelector('[data-name="state-name"]');
    labelStateName.innerText = state_name
    
    const labelCityName = currentCard.querySelector('[data-name="city-name"]');
    labelCityName.innerText = city_name
  }

  getCitiesSelected() {
    let selectedCitiesValues = Array.from(this.element.querySelectorAll(".select-cities"), option => option.value)
    let filteredValues = selectedCitiesValues.filter(value => value !== null && value !== '')
    return filteredValues
  }

  removeOptionsByIds(optionIds) {
    const currentForm = this.getCurrentForm()
    
    const selectElement = currentForm.querySelector(".select-cities");
    const optionsToRemove = Array.from(selectElement.options).filter(option => optionIds.includes(option.value));
    
    for (const option of optionsToRemove) {
      selectElement.removeChild(option);
    }
  }

  getCurrentForm() {
    const forms = this.getForms()
    return forms[forms.length - 1]
  }
  getForms() {
    return this.element.querySelectorAll(".nested-fields")
  }

  getStates() {
    const selectCountry = this.element.querySelector("#offer_country_id")
    const selectStates = this.element.querySelectorAll(".select-states")
    
    if (selectCountry.value.trim() !== '') {
      this.getStatesByCountry(selectCountry.value).then((data) => {
        selectStates.forEach((element) => {
          let defaultValueState = element.getAttribute("data-state-id")
          if (element.value === '' || defaultValueState ) {
            element.innerHTML = data
            element.value = defaultValueState
          }

          // Form created
          if(defaultValueState) {
            let parentForm = element.closest(".nested-fields")
            parentForm.hidden = true
          }
        })
      })      
    }
  }

  getDataForm() {
    const currentForm = this.getCurrentForm()
    const selectStates = currentForm.querySelector(".select-states")
    const selectCities = currentForm.querySelector(".select-cities")

    const state_name = selectStates.options[selectStates.selectedIndex]?.text
    const city_name = selectCities.options[selectCities.selectedIndex]?.text
    const city_id = selectCities.value

    return {state_name, city_name, city_id}
  }

  changeState(event) {
    const selectState = event.target
    const parentForm = selectState.closest(".nested-fields")
    const selectCities = parentForm.querySelector(".select-cities")
    const selectedCities = this.getCitiesSelected()

    fetch(`/cities?state_id=${selectState.value}`)
      .then(response => response.text())
      .then(html => {
        selectCities.innerHTML = html
        this.removeOptionsByIds(selectedCities)
      })
  }

  toogleShowForm() {
    const checkboxAcceptCities = this.check_accepts_citiesTarget  
    const formOtherCities = this.form_other_citiesTarget
    let currentForm  = this.getCurrentForm()
    
    if (currentForm) {
      currentForm.classList.remove("hidden")
    }

    if (checkboxAcceptCities.checked) {
      formOtherCities.classList.remove('hidden')
      this.getStates()
    } else {
      formOtherCities.classList.add('hidden')
    } 
  }

  toogleShowAdditionalCities() {
    const checkboxAcceptCandidatesByState= this.checkbox_accept_candidates_by_stateTarget
    if(checkboxAcceptCandidatesByState.checked) {
      this.wrapper_other_citiesTarget.classList.add('hidden')
    } else {
      this.wrapper_other_citiesTarget.classList.remove('hidden')
    }
  }

  deleteForm(event) {
    let idElement = event.target.dataset.idOffercity
    const elementFields = this.element.querySelector(`[data-offercity-id-fields="${idElement}"]`);

    if(elementFields) {
      let checkboxDestroy = elementFields.querySelector(".checkbox-offercity")
      checkboxDestroy.value = 1
      checkboxDestroy.checked = true
    }
    
    const elementCard = event.target.closest(".card_item")
    const elementsAux = elementCard.querySelector('[data-name="id-city"]');
    
    elementCard.remove()
    const containerFields = this.findSelectCity(elementsAux.value)
    if(containerFields) {
      let checkboxDestroy = containerFields.querySelector(".checkbox-offercity")
      checkboxDestroy.value = 1
      checkboxDestroy.checked = true
    }

    this.count--
  }


  findSelectCity(selectedValue) {
    const selectElements = this.element.getElementsByTagName("select");

    for (let selectElement of selectElements) {
      const options = selectElement.options;
      for (let option of options) {
        if (option.value === selectedValue && option.selected) {
          return selectElement.closest(".nested-fields")
        }
      }
    }
  }

  getDefaultStates(){
    const selectMainStates = this.element.querySelector("#offer_state_id")
    const selectCountry = this.element.querySelector("#offer_country_id")

    const defaultValueState = selectMainStates.getAttribute("data-state-id") 
    selectMainStates.value = defaultValueState

    this.getStatesByCountry(selectCountry.value).then((data) => {
      selectMainStates.innerHTML = data
      selectMainStates.value = defaultValueState
    })
  }

  getStatesByCountry(countryId) {
    return fetch(`/states?country_id=${countryId}`)
    .then(response => response.text())
    .then(html => html)
  }


  // Fetch related cities and add them dynamically
  async fetchRelatedCities(event) {
    event.preventDefault();
    this.removeFetchedElements(); 
    const cityId = this.element.querySelector("#offer_city_id").value;
    if (cityId) {
      try {
        const response = await fetch(`/related_cities?city_id=${encodeURIComponent(cityId)}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        this.addFetchedCities(data.related_cities);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  }

  // Add the fetched cities to the form
  addFetchedCities(relatedCities) {
    relatedCities.forEach(([cityId, stateId, cityName, stateName]) => {
      if (this.count < this.limit) {
        this.addFetchedCityToForm(cityId, stateId, cityName, stateName);
      }
    });
  }

  // Add a single fetched city to the form
  addFetchedCityToForm(cityId, stateId, cityName, stateName) {
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime());
    this.add_objectTarget.insertAdjacentHTML('beforebegin', content);

    const newForm = this.add_objectTarget.previousElementSibling;
    newForm.classList.add("fetched-association"); 

    if (newForm && !newForm.getAttribute("data-offercity-id-fields")) {
      newForm.setAttribute("data-offercity-id-fields", cityId);
      newForm.hidden = true;

      const card = this.template_cardTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime());
      this.add_card_objectTarget.insertAdjacentHTML('beforeend', card);
      
      const newCardContainer = this.add_card_objectTarget.lastElementChild;
      const newCard = newCardContainer.querySelector('.card_item');
      newCardContainer.classList.add("fetched-association"); // Mark as fetched
  
      this.fillFetchedDataCard(cityId, stateId, cityName, stateName, newForm);
    }

    this.count++;
  }

  // Fill card data with the fetched city information
  fillFetchedDataCard(cityId, stateId, cityName, stateName, form) {
    const cards = this.element.querySelectorAll(".card_item");
    const currentCard = cards[cards.length - 1];
    currentCard.classList.remove("hidden");

    const idCity = currentCard.querySelector('[data-name="id-city"]');
    const labelStateName = currentCard.querySelector('[data-name="state-name"]');
    const labelCityName = currentCard.querySelector('[data-name="city-name"]');

    idCity.value = cityId;
    labelStateName.innerText = stateName;
    labelCityName.innerText = cityName;

    if (form) {
      const selectStates = form.querySelector(".select-states");
      const selectCities = form.querySelector(".select-cities");
      const selectCountry = this.element.querySelector("#offer_country_id");

      if (selectCountry.value.trim() !== '') {
        this.getStatesByCountry(selectCountry.value).then((data) => {
          selectStates.innerHTML = data;
          selectStates.value = stateId;

          const cityOption = new Option(cityName, cityId, true, true);
          selectCities.add(cityOption);
        });
      }
    }
  }

   // Remove only the fetched elements
   removeFetchedElements() {
    // Select elements that were marked as fetched-association
    const fetchedElements = this.element.querySelectorAll(".fetched-association");
    
    fetchedElements.forEach(element => {
      element.remove();
    });
  
    this.count -= fetchedElements.length;

    if (this.add_objectTarget) {
      this.add_objectTarget.style.display = "block";
    }
  }
}