import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['googleBtn']

  connect() {
    this.loadGoogleClient().then(() => {
      this.initializeGoogleAuth()
    }).catch(error => {
      console.error("Error loading Google Identity Services client: ", error)
    })
  }

  loadGoogleClient() {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
        resolve()
        return
      }
      const script = document.createElement("script")
      script.src = "https://accounts.google.com/gsi/client"
      script.async = true
      script.defer = true
      script.onload = () => {
        resolve()
      }
      script.onerror = (error) => {
        reject(error)
      }
      document.head.appendChild(script)
    })
  }

  async clientId() {
    const response = await fetch('/api/secrets/NEW_GOOGLE_CLIENT_ID')
    const data = await response.json()
    return data.value
  }

  async initializeGoogleAuth() {
    const clientId = await this.clientId()
    google.accounts.id.initialize({
      client_id: clientId,
      callback: this.handleCredentialResponse.bind(this),
      prompt_parent_id: 'google_btn',
      allowed_parent_origin: 'https://*.hoytrabajas.com',
      style: { position: 'absolute', top: '0', right: '30px', width: '0', height: '0', zIndex: '1001' }
    })
    this.renderButton()
  }

  renderButton() {
    const parent = this.googleBtnTarget
    const width_btn = parent.offsetWidth < 400 ? parent.offsetWidth : 400
    const options = { type: "standard", shape: "pill", theme: "outline", text: "signin_with", size: "large", logo_alignment: "center", width: width_btn }
    if (parent) {
      google.accounts.id.renderButton(parent, options)
      google.accounts.id.prompt()
    } else {
      console.error("Parent element not found")
    }
  }

  handleCredentialResponse(response) {
    const { credential: id_token } = response

    fetch('/auth/google_oauth2/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ id_token: id_token })
    })
      .then(response => response.json())
      .then(data => {
        window.location.href = data.path
      })
      .catch(error => {
        console.error('Error during login request:', error)
      })
  }
}
