import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "transitionTo",
    "checkItem",
    "selectAll",
    "cardState",
    "checkItemsCounter",
    "itemsTotal",
  ];
  static values = {
    hidden: Boolean,
  };

  connect() {
    this.checkBoxes = this.checkItemTargets;
    this.validatePresenceSelectAll();
    this.checkCounter = 0;
    if (this.hasItemsTotalTarget) {
      this.itemsTotalTarget.textContent = this.checkItemTargets.length;
    }

    const isHidden = this.hasHiddenValue ? this.hiddenValue : true;
    if (isHidden) {
      this.hiddenCardStates();
    }
  }

  validatePresenceSelectAll() {
    if (this.hasSelectAllTarget) {
      this.checkAll = this.selectAllTarget;
      this.updateTransitions();
    } else {
      this.checkAll = null;
    }
  }

  hiddenCardStates() {
    const checked_element = this.checkBoxes.find((el) => el.checked);

    if (checked_element) {
      const { state } = checked_element.dataset;

      this.card = this.cardStateTargets.find((card) => card.id === state);

      const inputs = this.card.querySelectorAll('input, textarea, select')

      inputs.forEach((input) => {
        input.removeAttribute('disabled')
      })

      this.card.classList.remove("hidden");
    } else if (this.card) {
      this.card.classList.add("hidden");
    }
  }

  enableStatusTransitions(visibility) {
    // Enables the buttons for transition status
    this.transitionToTargets.forEach((t) => (t.style.visibility = visibility));
  }

  updateTransitions() {
    const someItemsSelected = this.checkItemTargets
      .map((t) => t.checked)
      .reduce((prev, current) => prev || current, false);

    if (someItemsSelected || this.selectAllTarget.checked) {
      this.enableStatusTransitions("visible");
    } else {
      this.enableStatusTransitions("invisible");
    }
  }

  checkAllItems() {
    this.checkItemTargets.forEach((t) => (t.checked = true));
    this.checkCounter = this.checkItemTargets.length;
    this.checkItemsCounterTarget.textContent = this.checkCounter;
  }

  uncheckAllItems() {
    this.checkItemTargets.forEach((t) => (t.checked = false));
    this.checkItemsCounterTarget.textContent = 0;
    this.checkCounter = 0;
  }

  selectAll(event) {
    if (event.target.checked) {
      this.checkAllItems();
      if (this.card) {
        this.card.classList.remove("hidden");
      }
    } else {
      this.uncheckAllItems();
      if (this.card) {
        this.card.classList.add("hidden");
      }
    }
    this.updateTransitions();
  }

  selectItem(event) {
    if (!event.target.checked) {
      this.selectAllTarget.checked = false;
      this.checkCounter -= 1;
    }
    if (event.target.checked) {
      this.checkCounter += 1;
    }

    if (this.targets.has("checkItemsCounter")) {
      this.checkItemsCounterTarget.textContent = this.checkCounter;
    }

    this.hiddenCardStates();
    this.updateTransitions();
  }
}
