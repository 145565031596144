import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  saveValue(event) {
    const value = event.target.checked

    fetch('/session_state', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ value: value, applied_offer_id: event.target.value })
    })
  }
}
