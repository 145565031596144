import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["searchInput", "dropdown", "hiddenInput"]
  static values = { endpointList: String, locale: String }
  selectedText = "";
  connect(){
    this.searchInputTarget.addEventListener('focus', () => this.clearInputOnFocus());
  }
  // Clears the input when it gains focus.
  clearInputOnFocus() {
    this.searchInputTarget.value = '';
    this.hiddenInputTarget.value = '';
    this.selectedText = '';
    this.searchInputTarget.setAttribute('autocomplete', 'off')
  }
  // Handles the search input event.
  search(event) {
    const inputValue = this.searchInputTarget.value;
    // If the input value is different from the selected text, clear the selection
    // and start a new search.
    if (inputValue !== this.selectedText) {
      this.hiddenInputTarget.value = '';
      this.selectedText = "";
      if (inputValue.length >= 3) {
        this.fetchData(inputValue);
      } else if (inputValue.length === 0) {
        this.hideDropdown();
      }
    }
  }
  // Fetches data based on the input value.
  fetchData(inputValue) {
    const endpoint = `/${this.endpointListValue}?description=${inputValue}&locale=${this.localeValue}`;
    fetch(endpoint)
      .then(response => response.json())
      .then(data => this.populateDropdown(data))
      .catch(error => console.error('Error:', error));
  }
  // Populates the dropdown with data items.
  populateDropdown(data) {
    this.dropdownTarget.innerHTML = '';
    data.forEach(item => this.createDropdownItem(item));
    this.dropdownTarget.style.display = data.length ? 'flex' : 'none';
  }
  // Creates a dropdown item.
  createDropdownItem(item) {
    const element = document.createElement('a');
    element.href = '#';
    element.classList.add('py-3', 'px-2', 'lg:py-1', 'hover:bg-blue-100', 'rounded');
    element.textContent = item[0];
    element.dataset.value = item[1];
    element.addEventListener('click', (e) => this.selectOption(e, item));
    this.dropdownTarget.appendChild(element);
  }
  // Handles the selection of a dropdown item.
  selectOption(event, item) {
    event.preventDefault();
    let textSelect = item[0];
    let idSelect = item[1];
    this.selectedText = textSelect;
    this.searchInputTarget.value = textSelect;
    this.hiddenInputTarget.value = idSelect;
    this.hideDropdown();
  }
  // Hides the dropdown menu.
  hideDropdown() {
    this.dropdownTarget.style.display = 'none';
  }
  // Clears the selection if no value is selected.
  clearSelection() {
    if (this.hiddenInputTarget.value === '') {
      this.searchInputTarget.value = '';
    }
  }
  // Hides dropdown when clicking outside.
  hideOnClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.style.display = 'none';
      this.clearSelection();
    }
  }
  // Checks if the current input matches any dropdown item.
  checkInputAgainstDropdown() {
    const currentText = this.searchInputTarget.value;
    if (currentText !== this.selectedText) {
      this.hiddenInputTarget.value = '';
      this.selectedText = "";
    }
  }
}